import React, {Component} from 'react';
import Box, {Item} from 'devextreme-react/box';
import {TextBox, TextArea} from 'devextreme-react';
import LDH from '../helpers/LeopardDataHelper';
import LeopardTooltipWithIcon from "./LeopardTooltipWithIcon";
import LRH from "../helpers/LeopardReactHelper";

class LeopardDataSourcePanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            documentDefinitionDataSources: null
        };
        this.uiObjectInstances = [];
    }

    componentDidMount() {
        if (!LDH.IsObjectNull(this.props.documentDefinitionDataSources)) {
            let data = this.props.documentDefinitionDataSources;
            this.setState({documentDefinitionDataSources: data});
        } else {
            this.setState({documentDefinitionDataSources: []});
        }
    }

    updateValueForDataSources = (data) => {
        let that = this;
        let clonedDataSources = LDH.DeepClone(this.state.documentDefinitionDataSources);
        for (let i = 0; i < clonedDataSources.length; i++) {
            let currentColumn = clonedDataSources[i];
            if (currentColumn.id === data.id) {
                let pName = data.propertyName;
                currentColumn[pName] = data.e.value;
            }
        }
        this.setState({
            documentDefinitionDataSources: clonedDataSources
        }, function () {
            that.props.documentDefinitionDataSourcesOnChanged(
                that.state.documentDefinitionDataSources);
        });
    };

    dataSourceDeleteOnClick = (data) => {
        let that = this;
        let clonedDataSources = LDH.DeepClone(this.state.documentDefinitionDataSources);
        let filteredDataSources = [];
        for (let i = 0; i < clonedDataSources.length; i++) {
            if (clonedDataSources[i].id !== data.id) {
                filteredDataSources.push(clonedDataSources[i]);
            }
        }
        this.setState({documentDefinitionDataSources: filteredDataSources}, function () {
            that.props.documentDefinitionDataSourcesOnChanged(
                that.state.documentDefinitionDataSources);
        });
    };

    dataSourceAddOnClick = () => {
        let that = this;
        if (this.state.documentDefinitionDataSources.length > 30) {
            LRH.ShowToast("You have already reached the maximum of 30 data sources.", "error", 5000);
            return;
        }
        let newDataSource = {id: LDH.GenerateGuid(), dataSourceName: "", dataSourceUrl: ""};
        this.setState({
            documentDefinitionDataSources: [...this.state.documentDefinitionDataSources, newDataSource]
        }, function () {
            that.props.documentDefinitionDataSourcesOnChanged(
                that.state.documentDefinitionDataSources);
        });
    };

    addUIObjectInstance = (data) => {
        if (LDH.IsObjectNull(data.ref) || LDH.IsObjectNull(data.ref.ref) ||
            LDH.IsObjectNull(data.ref.ref.instance)) {
            return;
        }
        this.uiObjectInstances[data.uiObjectName] = data.ref.ref.instance;
    };

    render() {
        let that = this;
        if (LDH.IsObjectNull(this.state.documentDefinitionDataSources)) return null;

        let result = this.state.documentDefinitionDataSources.map(function (item, i) {
            return (
                <Item key={i} baseSize={300}>
                    <div className="leopard-settings-panel-cell">
                        <div className="leopard-settings-panel-title" style={{float: "left", width: "243px"}}>
                            <span>{LDH.IsValueEmpty(that.state.documentDefinitionDataSources[i].dataSourceName)
                                ? "Data Source " + (i + 1)
                                : that.state.documentDefinitionDataSources[i].dataSourceName}
                            </span>
                        </div>
                        <div style={{float: "right", width: "22px", textAlign: "center", marginRight: "10px"}}>
                            <i className="leopard-configurepanel-title-button remove fas fa-minus"
                               onClick={(e) => that.dataSourceDeleteOnClick({
                                   e, id: that.state.documentDefinitionDataSources[i].id
                               })}></i>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon elementId={"documentEditorDataSourceName_" + i + "_help"}
                                                        title={"Data source name"}
                                                        text={"Give a name for the data source."}/>
                                <span>Data source name:</span>
                            </div>
                            <div>
                                <TextBox value={that.state.documentDefinitionDataSources[i].dataSourceName}
                                         maxLength={20}
                                         onValueChanged={(e) => that.updateValueForDataSources({
                                             e, id: that.state.documentDefinitionDataSources[i].id,
                                             propertyName: "dataSourceName", item: item
                                         })}/>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon elementId={"documentEditorDataSourceUrl_" + i + "_help"}
                                                        title={"Data source URL"}
                                                        text={"Specify an URL for the data source."}/>
                                <span>Data source URL:</span>
                            </div>
                            <div>
                                <TextBox value={that.state.documentDefinitionDataSources[i].dataSourceUrl}
                                         onValueChanged={(e) => that.updateValueForDataSources({
                                             e, id: that.state.documentDefinitionDataSources[i].id,
                                             propertyName: "dataSourceUrl", item: item
                                         })}/>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon elementId={"documentEditorDataKey_" + i + "_help"}
                                                        title={"Data source URL"}
                                                        text={"Specify a data key for integrating with an UI control."}/>
                                <span>Data key:</span>
                            </div>
                            <div>
                                <TextBox value={that.state.documentDefinitionDataSources[i].dataKey}
                                         onValueChanged={(e) => that.updateValueForDataSources({
                                             e, id: that.state.documentDefinitionDataSources[i].id,
                                             propertyName: "dataKey", item: item
                                         })}/>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon elementId={"documentEditorDataValue_" + i + "_help"}
                                                        title={"Data value"}
                                                        text={"Specify a data value for integrating with an UI control."}/>
                                <span>Data value:</span>
                            </div>
                            <div>
                                <TextBox value={that.state.documentDefinitionDataSources[i].dataValue}
                                         onValueChanged={(e) => that.updateValueForDataSources({
                                             e, id: that.state.documentDefinitionDataSources[i].id,
                                             propertyName: "dataValue", item: item
                                         })}/>
                            </div>
                        </div>
                        <div className="leopard-settings-panel-contentgroup">
                            <div className="leopard-settings-panel-content-label">
                                <LeopardTooltipWithIcon elementId={"documentEditorDataSourceUrl_" + i + "_help"}
                                                        title={"Data shaping logic"}
                                                        text={"Write a JavaScript code to shape the data before being used by the UI controls."}/>
                                <span>Data shaping logic:</span>
                            </div>
                            <div>
                                <TextArea value={that.state.documentDefinitionDataSources[i].dataShapingLogic}
                                          height={100}
                                          placeholder={"Use the following variables to get started: data. Must return a value."}
                                          onValueChanged={(e) => that.updateValueForDataSources({
                                              e, id: that.state.documentDefinitionDataSources[i].id,
                                              propertyName: "dataShapingLogic", item: item
                                          })}>
                                </TextArea>
                            </div>
                        </div>
                    </div>
                </Item>
            );
        });

        return (
            <Box direction={'row'} width={'100%'}>
                <Item baseSize={60}>
                    <div className="leopard-settings-panel-cell">
                        <div style={{float: "right", width: "22px", textAlign: "center", marginRight: "10px"}}>
                            <i className="leopard-configurepanel-title-button fas fa-plus"
                               onClick={(e) => this.dataSourceAddOnClick(e)}></i>
                        </div>
                    </div>
                </Item>
                {result}
            </Box>
        )
    }
}

export default LeopardDataSourcePanel;
