import React from 'react';
import {PubSub, Amplify} from 'aws-amplify';
import LDH from '../helpers/LeopardDataHelper';
import {MqttOverWSProvider} from "@aws-amplify/pubsub/lib/Providers";
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";

class LeopardPubSubHelper extends React.Component {
    static RegisterWebsocketProvider = (wssEndpoint) => {
        Amplify.addPluggable(new MqttOverWSProvider({
            aws_pubsub_endpoint: wssEndpoint,
        }));
        PubSub.configure();
    };

    static PublishMessage = (message) => {
        PubSub.publish(message.topics, {
            msg: message.data
        }, {provider: 'MqttOverWSProvider'});
    };

    static SubscribeMessage = (message) => {
        let subscriptionId = LDH.GenerateGuid();
        let subscribedTopic = PubSub.subscribe(message.topic, {
            provider: 'MqttOverWSProvider'
        }).subscribe({
            next: function (data) {
                if (!LDH.IsObjectNull(message.callbackSuccess)) {
                    message.callbackSuccess(data);
                }
            },
            error: function (error) {
                if (!LDH.IsObjectNull(message.callbackError)) {
                    message.callbackError(error);
                }
            },
            close: function () {
                if (!LDH.IsObjectNull(message.callbackClose)) {
                    message.callbackClose();
                }
            }
        });
        LeopardStaticUIConfig.Global_WebsocketConnections.push({
            id: subscriptionId,
            topic: subscribedTopic,
            data: message
        });
    };

    static UnsubscribeMessage = (message) => {
        if (LDH.IsObjectNull(message.subscriptionId) ||
            LDH.IsValueEmpty(message.subscriptionId)) {
            return;
        }
        let subscriptions = LeopardStaticUIConfig.Global_WebsocketConnections;
        for (let i = 0; i < subscriptions.length; i++) {
            if (subscriptions[i].id === message.subscriptionId) {
                subscriptions[i].topic.unsubscribe();
                subscriptions.splice(i, 1);
                break;
            }
        }
        LeopardStaticUIConfig.Global_WebsocketConnections = subscriptions;
    };
}

export default LeopardPubSubHelper;

