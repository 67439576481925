import React, {Component} from 'react';
import App from './App';
import {Provider} from 'react-redux';
import {Authenticator, SignIn} from 'aws-amplify-react';
import LeopardUserLogin from './pages/LeopardUserLogin';
import {withCookies, Cookies} from 'react-cookie';
import {instanceOf} from 'prop-types';
import LDH from './helpers/LeopardDataHelper';

class LeopardAuthenticator extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            authState: "signIn",
            isTestMode: false,
            updateUserProfile: false
        }
    }

    componentDidMount = () => {
        let testMode = LDH.IsValueTrue(this.props.cookies.get('IsTestMode'));
        localStorage.setItem("IsTestMode", testMode);
        this.setState({isTestMode: testMode});
    };

    onStateChange = (e) => {
        let that = this;
        this.setState({authState: e}, function () {
            if (e === "signedIn") {
                that.setState({updateUserProfile: true});
            } else {
                that.setState({updateUserProfile: false});
            }
        });
    };

    updateUserProfileComplete = (callback) => {
        this.setState({updateUserProfile: false}, function () {
            if (!LDH.IsObjectNull(callback)) {
                callback();
            }
        });
    };

    render() {
        if (this.state.authState !== "signedIn") {
            return (
                <React.Fragment>
                    <Authenticator hideDefault={true} onStateChange={(e) => this.onStateChange(e)}
                                   authState="signIn">
                        <LeopardUserLogin override={SignIn} isTestMode={this.state.isTestMode}/>
                    </Authenticator>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <Authenticator onStateChange={(e) => this.onStateChange(e)} authState="signIn"
                               hideDefault={true}>
                    <LeopardUserLogin override={SignIn} isTestMode={this.state.isTestMode}/>
                    <Provider store={this.props.store}>
                        <div id={"RootWindowContainer"}>
                            <App authState={this.state.authState}
                                 updateUserProfile={this.state.updateUserProfile}
                                 updateUserProfileComplete={this.updateUserProfileComplete}/>
                        </div>
                    </Provider>
                </Authenticator>
            </React.Fragment>
        );
    }
}

export default withCookies(LeopardAuthenticator);