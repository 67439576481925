import React, {Component} from 'react';
import {connect} from 'react-redux';
import $ from 'jquery';
import RGL, {WidthProvider} from "react-grid-layout";
import {Button, Switch} from 'devextreme-react';

import LRH from '../helpers/LeopardReactHelper';
import LDH from '../helpers/LeopardDataHelper';
import LeopardChildGridView from '../foundation/LeopardChildGridView';
import LeopardChildChart from '../foundation/LeopardChildChart';
import LeopardAjaxHelper from '../helpers/LeopardAjaxHelper';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import LeopardChildAttachableWidget from "../foundation/LeopardChildAttachableWidget";
import LeopardDocumentEditorConfiguration from "../components/LeopardDocumentEditorConfiguration";

class LeopardTemplateView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            templateLayout: null,
            dataViewList: [],
            relationships: [],
            dashboardInitialized: false
        };

        this.menuItems = [];
        this.uiInstances = [];
        this.ajaxLoadDataCount = 0;
        this.dashboardLayout = [];
        this.dataViewListToAdd = [];
        this.addDataViewListProcess = null;
    }

    componentDidMount = () => {
        LeopardStaticUIConfig.Global_DashboardDataViewListeners = [];
        LeopardStaticUIConfig.Global_DashboardAutoSelectByDefault = true;
        window.Global_TempDateConvertion = [];
        this.loadLayoutDefinitionByAjaxRequest();
    };

    componentWillUnmount = () => {
        this.uiInstances = [];
        this.setState({templateLayout: [], relationships: []});
    };

    loadLayoutDefinitionByAjaxRequest = () => {
        let that = this;
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);
        let dashboardId = this.props.menuItem.dashboardId;

        LeopardAjaxHelper.GetUserProfile(userId, function (profileData) {
            let userProfileCache = profileData;

            LeopardAjaxHelper.GetDashboardById(userId, dashboardId, function (data) {
                let layout = data.dashboardLayout;
                let relationships = data.relationships;
                that.setState({templateLayout: layout, relationships});
                that.dashboardLayout = layout;
                that.dataViewListToAdd = [];

                let settingsVersion = data.settingsVersion;
                if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
                $(".dashboard-settings-version").attr("settingsversion", settingsVersion);

                LeopardAjaxHelper.GetLeftMenuItems(userId, function (items) {
                    that.menuItems = items;
                    if (LDH.IsObjectNull(layout)) {
                        LRH.ShowToast("Your Workspace might have been deleted or moved. " +
                            "Please refresh this page to update the Control Centre.", "error", 5000);
                        LRH.ShowOrHideMenuLoadingProgress(false);
                        LRH.ShowUnhandledExceptionForMasterContent();
                        return;
                    }

                    for (let i = 0; i < layout.length; i++) {
                        let dataViewPersistentId = layout[i].definition.dataViewPersistentId;
                        let item = null;
                        for (let j = 0; j < items.length; j++) {
                            if (!LDH.IsObjectNull(items[j].dataViewPersistentId) &&
                                !LDH.IsValueEmpty(items[j].dataViewPersistentId) &&
                                items[j].dataViewPersistentId === dataViewPersistentId) {
                                item = items[j];
                            }
                        }
                        let dataViewId = (item == null) ? "empty" : item.dataViewId;

                        // Get each data view by id.
                        LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (dataView) {
                            if (dataView === null || dataView.length === 0) {
                                that.ajaxLoadDataCount += 1;
                                if (that.ajaxLoadDataCount >= layout.length) {
                                    that.setState({dashboardInitialized: true});
                                }
                            } else {
                                if (dataView.dataViewType === "datagrid") {
                                    // Get column list.
                                    let tableName = dataView.dataSourceUrl;
                                    let limitedColumns = [];

                                    if (!LDH.IsObjectNull(dataView.dataViewNote) && !LDH.IsObjectNull(dataView.dataViewNote.gridViewDefinition)) {
                                        for (let u = 0; u < dataView.dataViewNote.gridViewDefinition.columnDefinition.length; u++) {
                                            if (!LDH.IsValueEmpty(dataView.dataViewNote.gridViewDefinition.columnDefinition[u].isEnabled) &&
                                                dataView.dataViewNote.gridViewDefinition.columnDefinition[u].isEnabled === false) {
                                                limitedColumns.push(dataView.dataViewNote.gridViewDefinition.columnDefinition[u].columnName);
                                            }
                                        }
                                    }

                                    LeopardAjaxHelper.GetDataTableColumnsByDataSourceUrl(tableName, limitedColumns, function (data) {
                                            dataView.columnFieldList = data;
                                            that.dataViewListToAdd.push(dataView);
                                            that.ajaxLoadDataCount += 1;

                                            if (that.ajaxLoadDataCount >= layout.length) {
                                                that.setState({dashboardInitialized: true});
                                            }
                                        }, function (error, sessionTimeout) {
                                            if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) &&
                                                !LDH.IsValueEmpty(error.message) &&
                                                error.message.indexOf("Cannot read property '") !== -1 &&
                                                error.message.indexOf("' of undefined") !== -1) {
                                                LRH.ShowToast("Your Workspace might have been deleted or moved. " +
                                                    "Please refresh this page to update the Control Centre.", "error", 5000);
                                            } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                                            } else {
                                                LRH.ShowToast("Failed to retrieve data for your Workspace. " +
                                                    "Please check your network connection status and try again.", "error", 5000);
                                                localStorage.setItem("LoadFirstMenuItem", "false");
                                            }
                                            LRH.ShowOrHideMenuLoadingProgress(false);
                                            LRH.ShowUnhandledExceptionForMasterContent();
                                        }, dataView.dataViewNote.gridViewDefinition.dataSourceCustomQueryTableSchema,
                                        dataView.dataViewNote.gridViewDefinition.customComputedColumns);
                                } else {
                                    that.dataViewListToAdd.push(dataView);
                                    that.ajaxLoadDataCount += 1;

                                    if (that.ajaxLoadDataCount >= layout.length) {
                                        that.setState({dashboardInitialized: true});
                                    }
                                }
                            }
                        }, function (error, sessionTimeout) {
                            if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message) &&
                                error.message.indexOf("Cannot read property '") !== -1 &&
                                error.message.indexOf("' of undefined") !== -1) {
                                LRH.ShowToast("Your Workspace might have been deleted or moved. " +
                                    "Please refresh this page to update the Control Centre.", "error", 5000);
                            } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                            } else {
                                LRH.ShowToast("Failed to retrieve data for your Workspace. " +
                                    "Please check your network connection status and try again.", "error", 5000);
                            }
                            LRH.ShowOrHideMenuLoadingProgress(false);
                            LRH.ShowUnhandledExceptionForMasterContent();
                        }, userProfileCache)
                    }
                }, null, userProfileCache);
                that.addDataViewList();
            }, function (error, sessionTimeout) {
                if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message) &&
                    error.message.indexOf("Cannot read property '") !== -1 &&
                    error.message.indexOf("' of undefined") !== -1) {
                    LRH.ShowToast("Your Workspace might have been deleted or moved. " +
                        "Please refresh this page to update the Control Centre.", "error", 5000);
                } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to retrieve data for your Workspace. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                LRH.ShowOrHideMenuLoadingProgress(false);
                LRH.ShowUnhandledExceptionForMasterContent();
            }, userProfileCache);
        }, function () {

        }, null);
    };

    addDataViewList = () => {
        let that = this;
        let count = 0;

        that.addDataViewListProcess = setInterval(() => {
            if (this.state.dashboardInitialized === true) {
                this.setState({...this.state, dataViewList: this.dataViewListToAdd}, function () {
                    LRH.ShowOrHideMenuLoadingProgress(false);
                });
                clearInterval(that.addDataViewListProcess);
            }
            count++;
            if (count >= 600) {
                LRH.ShowOrHideMenuLoadingProgress(false);
                LRH.ShowUnhandledExceptionForMasterContent();
                clearInterval(that.addDataViewListProcess);
                LRH.ShowToast("Failed to load data. Please refresh your browser and try again.", "error", 5000);
            }
        }, 100);
    };

    updateWindowDimensionsRequiredForChart = () => {
        LRH.TriggerWindowResizeEvent();
    };

    updateWindowDimensionsRequiredForPhotoGallery = () => {
        LRH.TriggerWindowResizeEvent();
    };

    updateWindowDimensionsRequiredForNotepad = () => {
        LRH.TriggerWindowResizeEvent();
    };

    updateWindowDimensionsRequiredForDocumentEditor = () => {
        LRH.TriggerWindowResizeEvent();
    };

    onResizeCallback = (layout, oldItem, newItem, placeholder, e, element) => {
        let $parent = $(element).closest(".react-grid-item");
        if ($parent === null || $parent.length === 0) return;

        let $layout = $(".leopard-templateview-cell-layout", $parent);
        if ($layout === null || $layout.length === 0) return;

        let layoutId = $layout.attr("custom_attr_id").trim();
        let height = $parent.height();
        this.dashboardLayout = layout;

        for (let i = 0; i < this.uiInstances.length; i++) {
            let instanceObj = this.uiInstances[i];
            if (instanceObj.instance === null) continue;
            if (instanceObj.id === layoutId && instanceObj.type === "chart") {
                instanceObj.instance.option("size", {width: "100%"});
            }

            if (instanceObj.id === layoutId && instanceObj.type === "photo") {
                instanceObj.instance.option("height", height - 55);
            }

            if (instanceObj.id === layoutId && instanceObj.type === "notepad") {
                instanceObj.instance.option("height", height - 40);
            }

            if (instanceObj.id === layoutId && instanceObj.type === "datagrid") {
                if (instanceObj.optimizePagerForLargeDataset) {
                    height = height - 98;
                } else {
                    height = height - 62;
                }
                instanceObj.instance.option("height", height);
            }
        }
    };

    resizeOnAllPanels = () => {
        let that = this;
        let $root = $(".leopard-right-panel-container");
        $(".react-grid-item", $root).each(function () {
            let $handle = $(".react-resizable-handle", $(this));
            that.onResizeCallback(null, null, null, null, null, $handle);
        });
    };

    setGridViewInstance = (instanceData) => {
        let found = false;
        instanceData.isDataView = false;

        for (let i = 0; i < this.uiInstances.length; i++) {
            if (this.uiInstances[i].id === instanceData.id) {
                found = true;
            }
        }
        if (found === false) {
            this.uiInstances.push(instanceData);
        }
        this.props.setGridViewInstance(instanceData);

        if (instanceData.isDataView === false) {
            this.resizeOnAllPanels();
        }
    };

    setChartInstance = (instanceData) => {
        let found = false;
        instanceData.isDataView = false;

        for (let i = 0; i < this.uiInstances.length; i++) {
            if (this.uiInstances[i].id === instanceData.id) {
                found = true;
            }
        }
        if (found === false) {
            this.uiInstances.push(instanceData);
        }
        this.props.setChartInstance(instanceData);

        if (instanceData.isDataView === false) {
            this.resizeOnAllPanels();
        }
    };

    setPhotoInstance = (instanceData) => {
        let found = false;
        instanceData.isDataView = false;

        for (let i = 0; i < this.uiInstances.length; i++) {
            if (this.uiInstances[i].id === instanceData.id) {
                found = true;
            }
        }
        if (found === false) {
            this.uiInstances.push(instanceData);
        }
        this.props.setPhotoInstance(instanceData);

        if (instanceData.isDataView === false) {
            this.resizeOnAllPanels();
        }
    };

    setNotepadInstance = (instanceData) => {
        let found = false;
        instanceData.isDataView = false;

        for (let i = 0; i < this.uiInstances.length; i++) {
            if (this.uiInstances[i].id === instanceData.id) {
                found = true;
            }
        }
        if (found === false) {
            this.uiInstances.push(instanceData);
        }
        this.props.setNotepadInstance(instanceData);

        if (instanceData.isDataView === false) {
            this.resizeOnAllPanels();
        }
    };

    setDocumentEditorInstance = (instanceData) => {
        let found = false;
        instanceData.isDataView = false;

        for (let i = 0; i < this.uiInstances.length; i++) {
            if (this.uiInstances[i].id === instanceData.id) {
                found = true;
            }
        }
        if (found === false) {
            this.uiInstances.push(instanceData);
        }
        this.props.setDocumentEditorInstance(instanceData);

        if (instanceData.isDataView === false) {
            this.resizeOnAllPanels();
        }
    };

    onButtonSaveLayoutOnClick = () => {
        let that = this;
        if (this.dashboardLayout === null || this.dashboardLayout.length === 0) {
            LRH.ShowToast("You have not made any changes to the Workspace layout.", "warning", 5000);
            return;
        }

        $(".leopard-savelayout-loading").css("visibility", "visible");
        setTimeout(() => {
            let userProfile = window.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let dashboardId = that.props.menuItem.dashboardId;

            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
            LRH.ConsoleLog(that, "LeopardTemplateView_OnButtonSaveLayoutOnClick",
                that.dashboardLayout);

            LeopardAjaxHelper.UpdateDashboardLayout(userId, organizationId,
                dashboardId, that.dashboardLayout, function () {
                    let settingsVersionOnClient = parseInt($(".dashboard-settings-version").attr("settingsversion"));
                    $(".dashboard-settings-version").attr("settingsversion", settingsVersionOnClient + 1);

                    LRH.ShowToast("Your Workspace layout has been successfully saved.", "success", 5000);
                    LRH.ConsoleLog(that, "LeopardTemplateView_SaveLayout_Success", true);
                    $(".leopard-savelayout-loading").css("visibility", "hidden");
                }, function (error, sessionTimeout) {
                    if (error === "version-out-of-date") {
                        LRH.ShowStaticToast("Configuration outdated", "Your current configuration settings cannot be saved due to a newer version found on the server, please refresh your " +
                            "browser. ", "error", true);
                    } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to save your Workspace layout.", "error", 5000);
                    }
                    LRH.ConsoleLog(that, "LeopardTemplateView_SaveLayout_Failed", error);
                    $(".leopard-savelayout-loading").css("visibility", "hidden");
                });
        }, 100);
    };

    autoSelectOnValueChanged = (e) => {
        LeopardStaticUIConfig.Global_DashboardAutoSelectByDefault = e.value;
    };

    initAutoSelectInstance = (e) => {
        if (!LDH.IsObjectNull(e) && !LDH.IsObjectNull(e.instance)) {
            LeopardStaticUIConfig.Global_DashboardAutoSelectInstance = e.instance;
        } else {
            LeopardStaticUIConfig.Global_DashboardAutoSelectInstance = null;
        }
    };

    renderLoadingProgressIndicator = () => {
        if (this.state.dashboardInitialized && this.dataViewListToAdd.length === 0) {
            LRH.ShowOrHideMenuLoadingProgress(false);
            return (
                <React.Fragment>
                    <div style={{textAlign: "center", marginTop: "120px", fontFamily: "Arial", fontSize: "20px"}}>
                        <i className="far fa-meh" style={{color: "#FF8200"}}></i>
                        <div className={"leopard-empty-dataview-warning"}>
                            There is no content available to display. Please assign a Data View to this Workspace
                            first.
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        LRH.ShowOrHideMenuLoadingProgress(true);
        return (
            <React.Fragment>
        <span className={"leopard-loading-icon"} style={{width: "100%", height: "100%", marginLeft: "0px"}}>
          <i className="leopard-largeloading-progress-icon fas fa-spinner fa-pulse"></i>
          <span className="leopard-largeloading-progress-text">Loading data...</span>
        </span>
            </React.Fragment>
        );
    };

    renderBlankDataViewContent = (item) => {
        return (
            <div key={item.i}>
                <div className="leopard-templateview-cell-layout" custom_attr_id={item.definition.dashboardItemId}>
                    <div className={"leopard-templateview-cell-topbar leopard-bgcolor-orange"}>
                        <div className="topmenu-arrow-indicator"></div>
                        <span className="leopard-moving-handler-text">
              Empty Data View
            </span>
                    </div>
                    <div className="leopard-templateview-cell-content">
                        <div style={{textAlign: "center", marginTop: "120px", fontFamily: "Arial", fontSize: "20px"}}>
                            <i className="far fa-meh" style={{color: "#FF8200"}}></i>
                            <div style={{
                                width: "250px", marginLeft: "auto", fontSize: "12px", color: "#7F7F7F",
                                marginRight: "auto"
                            }}>
                                Oops! This content is currently blank due to the original Data View being deleted.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    setBorderColorForGridLayout = () => {
        setTimeout(function () {
            $(".leopard-templateview-cell-topbar").each(function () {
                let color = $(this).css("background-color");
                if (LDH.IsObjectNull(color) || LDH.IsValueEmpty(color)) {
                    return;
                }
                let $parent = $(this).closest(".react-grid-item");
                $parent.css("border-color", color);
            });
        }, 100);
    };

    initializeGridViewLayout = () => {
        let that = this;

        let dataViewList = this.state.dataViewList;
        let dataView = null;
        let dataViewNote = null;
        let dataSourceUrl = null;
        let relationships = LDH.SetRelationshipColorBar(this.state.relationships);

        return this.state.templateLayout.map(function (item) {
            if (item.definition.dashboardType === "datagrid") {
                dataView = null;

                let dataViewPersistentId = item.definition.dataViewPersistentId;
                for (let j = 0; j < dataViewList.length; j++) {
                    let def = dataViewList[j].dataViewNote.gridViewDefinition;
                    if (LDH.IsObjectNull(def)) continue;
                    if (dataViewPersistentId === def.dataViewPersistentId) {
                        dataView = dataViewList[j];
                        break;
                    }
                }

                if (dataView === null) {
                    return (that.renderBlankDataViewContent(item));
                }
                dataViewNote = dataView.dataViewNote;

                let gridDefinition = dataViewNote.gridViewDefinition;
                dataSourceUrl = "?tableName=" + dataView.dataSourceUrl;

                if (!LDH.IsObjectNull(gridDefinition.overrideDatabaseTableField) &&
                    !LDH.IsValueEmpty(gridDefinition.overrideDatabaseTableField)) {
                    dataSourceUrl = "?tableName=" + gridDefinition.overrideDatabaseTableField;
                }

                let version = LDH.ReplaceAll(LeopardStaticUIConfig.ControlCentreVersion, ".", "");
                LRH.ApplyCustomStyle(dataViewNote.styleDefinition, version);

                let color = "";
                let level = "";
                let dashboardId = item.definition.dashboardItemId;

                for (let v = 0; v < relationships.length; v++) {
                    if (relationships[v].parentDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[v].color;
                        level = relationships[v].level;
                    }
                    if (relationships[v].childDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[v].color;
                        level = relationships[v].level + 1;
                    }
                }
                that.setBorderColorForGridLayout();

                return (
                    <div key={item.i}>
                        <div className="leopard-templateview-cell-layout gridview"
                             custom_attr_id={item.definition.dashboardItemId}>
                            <div className={"leopard-templateview-cell-topbar " + item.definition.color}
                                 style={LDH.IsValueEmpty(color) ? {} : {backgroundColor: color}}>
                                <div className="topmenu-arrow-indicator"></div>
                                {
                                    LDH.IsObjectNull(item.definition.customDataViewTitle) || LDH.IsValueEmpty(item.definition.customDataViewTitle) ?
                                        <span className="leopard-moving-handler-text">{dataView.dataViewName}</span> :
                                        <span
                                            className="leopard-moving-handler-text">{item.definition.customDataViewTitle}</span>
                                }
                                {
                                    LDH.IsValueEmpty(level) ? "" :
                                        <span className={"leopard-dashboard-level-text"}>Level {level}</span>
                                }
                            </div>
                            <div className="leopard-templateview-cell-content">
                                {
                                    (LDH.IsObjectNull(dataView.columnFieldList) || LDH.IsObjectNull(dataView.columnFieldList.fullColumns) ||
                                        dataView.columnFieldList.fullColumns.length === 0) ? "" :
                                        <LeopardChildGridView
                                            gridViewId={item.definition.dashboardItemId}
                                            gridDefinition={gridDefinition}
                                            gridViewName={dataView.dataViewName}
                                            gridViewHeight={"auto"}
                                            dashboardLevel={level}
                                            viewOptionsText={dataView.dataViewName}
                                            setGridViewInstance={that.setGridViewInstance}
                                            dataSourceUrl={dataSourceUrl}
                                            relationships={that.state.relationships}
                                            columnFieldList={dataView.columnFieldList}
                                        />
                                }
                            </div>
                        </div>
                    </div>);
            }

            if (item.definition.dashboardType === "chart") {
                dataView = null;

                let dataViewPersistentId = item.definition.dataViewPersistentId;
                for (let k = 0; k < dataViewList.length; k++) {
                    let def = dataViewList[k].dataViewNote.chartDefinition;
                    if (LDH.IsObjectNull(def)) continue;
                    if (dataViewPersistentId === def.dataViewPersistentId) {
                        dataView = dataViewList[k];
                        break;
                    }
                }

                if (dataView === null) {
                    return (that.renderBlankDataViewContent(item));
                }

                let color = "";
                let level = "";
                let dashboardId = item.definition.dashboardItemId;
                for (let b = 0; b < relationships.length; b++) {
                    if (relationships[b].parentDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[b].color;
                        level = relationships[b].level;
                    }
                    if (relationships[b].childDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[b].color;
                        level = relationships[b].level + 1;
                    }
                }

                dataViewNote = dataView.dataViewNote;
                dataSourceUrl = window.GetDataTableByName + "?tableName=" + dataView.dataSourceUrl;
                that.setBorderColorForGridLayout();

                return (
                    <div key={item.i}>
                        <div className="leopard-templateview-cell-layout chart"
                             custom_attr_id={item.definition.dashboardItemId}>
                            <div className={"leopard-templateview-cell-topbar " + item.definition.color}
                                 style={LDH.IsValueEmpty(color) ? {} : {backgroundColor: color}}>
                                <div className="topmenu-arrow-indicator"></div>
                                {
                                    LDH.IsObjectNull(item.definition.customDataViewTitle) || LDH.IsValueEmpty(item.definition.customDataViewTitle) ?
                                        <span className="leopard-moving-handler-text">{dataView.dataViewName}</span> :
                                        <span
                                            className="leopard-moving-handler-text">{item.definition.customDataViewTitle}</span>
                                }
                                {
                                    LDH.IsValueEmpty(level) ? "" :
                                        <span className={"leopard-dashboard-level-text"}>Level {level}</span>
                                }
                            </div>
                            <div className="leopard-templateview-cell-content">
                                <LeopardChildChart
                                    dataSourceUrl={dataSourceUrl} definition={dataViewNote}
                                    palette={['#c3a2cc', '#b7b5e0', '#e48cba']}
                                    customizeTextYAxis={"#value#"}
                                    dashboardLevel={level}
                                    seriesType={"bar"} useStateStore={true}
                                    dataViewId={item.definition.dashboardItemId}
                                    chartType={dataViewNote.chartType}
                                    relationships={that.state.relationships}
                                    updateWindowDimensionsRequired={that.updateWindowDimensionsRequiredForChart}
                                    setChartInstance={that.setChartInstance}/>
                            </div>
                        </div>
                    </div>);
            }

            if (item.definition.dashboardType === "photo") {
                dataView = null;

                let dataViewPersistentId = item.definition.dataViewPersistentId;
                for (let q = 0; q < dataViewList.length; q++) {
                    let def = dataViewList[q].dataViewNote.photoDefinition;
                    if (LDH.IsObjectNull(def)) continue;
                    if (dataViewPersistentId === def.dataViewPersistentId) {
                        dataView = dataViewList[q];
                        break;
                    }
                }

                if (dataView === null) {
                    return (that.renderBlankDataViewContent(item));
                }

                let color = "";
                let level = "";
                let dashboardId = item.definition.dashboardItemId;

                for (let w = 0; w < relationships.length; w++) {
                    if (relationships[w].parentDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[w].color;
                        level = relationships[w].level;
                    }
                    if (relationships[w].childDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[w].color;
                        level = relationships[w].level + 1;
                    }
                }

                dataViewNote = dataView.dataViewNote;
                that.setBorderColorForGridLayout();

                return (
                    <div key={item.i}>
                        <div className="leopard-templateview-cell-layout photo"
                             custom_attr_id={item.definition.dashboardItemId}>
                            <div className={"leopard-templateview-cell-topbar " + item.definition.color}
                                 style={LDH.IsValueEmpty(color) ? {} : {backgroundColor: color}}>
                                <div className="topmenu-arrow-indicator"></div>
                                {
                                    LDH.IsObjectNull(item.definition.customDataViewTitle) || LDH.IsValueEmpty(item.definition.customDataViewTitle) ?
                                        <span className="leopard-moving-handler-text">{dataView.dataViewName}</span> :
                                        <span
                                            className="leopard-moving-handler-text">{item.definition.customDataViewTitle}</span>
                                }
                                {
                                    LDH.IsValueEmpty(level) ? "" :
                                        <span className={"leopard-dashboard-level-text"}>Level {level}</span>
                                }
                            </div>
                            <div className="leopard-templateview-cell-content">
                                <LeopardChildAttachableWidget
                                    definition={dataViewNote} dashboardLevel={level}
                                    useStateStore={true}
                                    dashboardItemType={item.definition.dashboardType}
                                    dataViewId={item.definition.dashboardItemId}
                                    relationships={that.state.relationships}
                                    updateWindowDimensionsRequired={that.updateWindowDimensionsRequiredForPhotoGallery}
                                    setPhotoInstance={that.setPhotoInstance}/>
                            </div>
                        </div>
                    </div>);
            }

            if (item.definition.dashboardType === "notepad") {
                dataView = null;

                let dataViewPersistentId = item.definition.dataViewPersistentId;
                for (let q = 0; q < dataViewList.length; q++) {
                    let def = dataViewList[q].dataViewNote.notepadDefinition;
                    if (LDH.IsObjectNull(def)) continue;
                    if (dataViewPersistentId === def.dataViewPersistentId) {
                        dataView = dataViewList[q];
                        break;
                    }
                }

                if (dataView === null) {
                    return (that.renderBlankDataViewContent(item));
                }

                let color = "";
                let level = "";
                let dashboardId = item.definition.dashboardItemId;

                for (let w = 0; w < relationships.length; w++) {
                    if (relationships[w].parentDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[w].color;
                        level = relationships[w].level;
                    }
                    if (relationships[w].childDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[w].color;
                        level = relationships[w].level + 1;
                    }
                }

                dataViewNote = dataView.dataViewNote;
                that.setBorderColorForGridLayout();

                return (
                    <div key={item.i}>
                        <div className="leopard-templateview-cell-layout notepad"
                             custom_attr_id={item.definition.dashboardItemId}>
                            <div className={"leopard-templateview-cell-topbar " + item.definition.color}
                                 style={LDH.IsValueEmpty(color) ? {} : {backgroundColor: color}}>
                                <div className="topmenu-arrow-indicator"></div>
                                {
                                    LDH.IsObjectNull(item.definition.customDataViewTitle) || LDH.IsValueEmpty(item.definition.customDataViewTitle) ?
                                        <span className="leopard-moving-handler-text">{dataView.dataViewName}</span> :
                                        <span
                                            className="leopard-moving-handler-text">{item.definition.customDataViewTitle}</span>
                                }
                                {
                                    LDH.IsValueEmpty(level) ? "" :
                                        <span className={"leopard-dashboard-level-text"}>Level {level}</span>
                                }
                            </div>
                            <div className="leopard-templateview-cell-content">
                                <LeopardChildAttachableWidget
                                    definition={dataViewNote} dashboardLevel={level}
                                    useStateStore={true}
                                    dashboardItemType={item.definition.dashboardType}
                                    dataViewId={item.definition.dashboardItemId}
                                    relationships={that.state.relationships}
                                    updateWindowDimensionsRequired={that.updateWindowDimensionsRequiredForNotepad}
                                    setNotepadInstance={that.setNotepadInstance}/>
                            </div>
                        </div>
                    </div>);
            }

            if (item.definition.dashboardType === "document-editor") {
                dataView = null;

                let dataViewPersistentId = item.definition.dataViewPersistentId;
                for (let q = 0; q < dataViewList.length; q++) {
                    let def = dataViewList[q].dataViewNote.documentDefinition;
                    if (LDH.IsObjectNull(def)) continue;
                    if (dataViewPersistentId === def.dataViewPersistentId) {
                        dataView = dataViewList[q];
                        break;
                    }
                }

                if (dataView === null) {
                    return (that.renderBlankDataViewContent(item));
                }

                let color = "";
                let level = "";
                let dashboardId = item.definition.dashboardItemId;

                for (let w = 0; w < relationships.length; w++) {
                    if (relationships[w].parentDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[w].color;
                        level = relationships[w].level;
                    }
                    if (relationships[w].childDataViewId ===
                        dashboardId + ":" + dataViewPersistentId) {
                        color = relationships[w].color;
                        level = relationships[w].level + 1;
                    }
                }

                dataViewNote = dataView.dataViewNote;
                that.setBorderColorForGridLayout();

                return (
                    <div key={item.i}>
                        <div className="leopard-templateview-cell-layout document-editor"
                             custom_attr_id={item.definition.dashboardItemId}>
                            <div className={"leopard-templateview-cell-topbar " + item.definition.color}
                                 style={LDH.IsValueEmpty(color) ? {} : {backgroundColor: color}}>
                                <div className="topmenu-arrow-indicator"></div>
                                {
                                    LDH.IsObjectNull(item.definition.customDataViewTitle) || LDH.IsValueEmpty(item.definition.customDataViewTitle) ?
                                        <span className="leopard-moving-handler-text">{dataView.dataViewName}</span> :
                                        <span
                                            className="leopard-moving-handler-text">{item.definition.customDataViewTitle}</span>
                                }
                                {
                                    LDH.IsValueEmpty(level) ? "" :
                                        <span className={"leopard-dashboard-level-text"}>Level {level}</span>
                                }
                            </div>
                            <div className="leopard-templateview-cell-content hide-scrollbar-horizontal-vertical">
                                <LeopardDocumentEditorConfiguration
                                    definition={dataViewNote} dashboardLevel={level} useStateStore={true}
                                    viewType={"workspace"} isDataView={false}
                                    dashboardItemType={item.definition.dashboardType}
                                    dataViewId={item.definition.dashboardItemId}
                                    relationships={that.state.relationships}
                                    updateWindowDimensionsRequired={that.updateWindowDimensionsRequiredForDocumentEditor}
                                    setDocumentEditorInstance={that.setDocumentEditorInstance}/>
                            </div>
                        </div>
                    </div>);
            }

            return (<div key={item.i}></div>);
        });
    };

    render() {
        if (this.state.dataViewList !== null && this.state.dataViewList.length > 0 &&
            this.state.templateLayout !== null && this.state.dashboardInitialized === true) {

            let showGridViewAdminToolbar = false;
            if (this.props.state.permissions !== undefined && this.props.state.permissions !== null) {
                showGridViewAdminToolbar = this.props.state.permissions.ShowGridViewAdminToolbar;
            }

            return (
                <React.Fragment>
                    {
                        showGridViewAdminToolbar === false ? "" :
                            <div style={{height: "20px", position: "relative", top: "-5px"}}>
                                 <span style={{padding: "0 0 0 10px"}}>
                                 <Switch width={"100px"} switchedOffText={"MANUAL MODE"} defaultValue={true}
                                         switchedOnText={"AUTO MODE"} ref={(e) => this.initAutoSelectInstance(e)}
                                         onValueChanged={(e) => this.autoSelectOnValueChanged(e)}
                                 />
                                </span>
                                <span style={{padding: "0 10px 0 15px"}}>
                                  <Button text={"Save Layout"} className="leopard-buttonlink"
                                          onClick={(e) => this.onButtonSaveLayoutOnClick(e)}/>
                                </span>
                                <span className={"leopard-savelayout-loading leopard-loading-icon"} style={{
                                    marginLeft: "0px", position: "relative", top: "5px", float: "none",
                                    visibility: "hidden"
                                }}>
                                  <i className="fas fa-spinner fa-pulse" style={{
                                      color: "#FF8100", fontSize: "25px"
                                  }}></i>
                                </span>
                            </div>
                    }
                    <ReactGridLayout className="layout" layout={this.state.templateLayout} cols={216} rowHeight={1}
                                     autoSize={true} isDraggable={true}
                                     draggableHandle={".leopard-templateview-cell-topbar"}
                                     onDragStop={this.onResizeCallback} onResize={this.onResizeCallback}
                                     onResizeStop={this.onResizeCallback} useCSSTransforms={false}>
                        {this.initializeGridViewLayout()}
                    </ReactGridLayout>
                </React.Fragment>
            );
        }
        return this.renderLoadingProgressIndicator();
    }
}

const ReactGridLayout = WidthProvider(RGL);

const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer)(LeopardTemplateView);
