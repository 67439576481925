import {Component} from 'react';

class LeopardStaticUIConfig extends Component {
    static GridView_ShowBorder = true;
    static GridView_CacheEnabled = false;
    static GridView_AllowEditing = true;
    static GridView_EditingMode = "row";
    static GridView_AllowColumnResizing = true;
    static GridView_ColumnResizingMode = "widget";
    static GridView_ExplicitFilterOption = "auto";
    static GridView_ColumnMinWidth = 50;
    static GridView_ErrorRowEnabled = false;
    static GridView_ColumnAutoWidth = true;
    static GridView_RepaintChangesOnly = true;
    static GridView_HighlightChanges = true;
    static GridView_AllowColumnReordering = true;
    static GridView_HoverStateEnabled = true;
    static GridView_GroupingEnabled = true;
    static GridView_ExportMaximumRowLimit = 3000;
    static GridView_RowAlternationEnabled = true;
    static GridView_FilterRow = true;
    static GridView_DefaultFilterValue = "";
    static ColumnChooser_Enabled = false;
    static Selection_Mode = "multiple";
    static LoadPanel_ShowPane = false;
    static LoadPanel_ShowIndicator = false;
    static Selection_SelectAllMode = "page";
    static Selection_ShowCheckBoxesMode = "onLongTap";
    static Sorting_Mode = "multiple";
    static HeaderFilter_Visible = true;
    static ColumnFixing_Enabled = true;
    static RemoteOperations_Sorting = true;
    static RemoteOperations_Paging = true;
    static RemoteOperations_Summary = true;
    static RemoteOperations_Grouping = true;
    static RemoteOperations_GroupPaging = true;
    static RemoteOperations_Filtering = true;
    static Pager_allowedPageSizes = [10, 25, 50];
    static FilterOperationsForString = ["contains", "notcontains", "startswith", "endswith", "=", "<>"];
    static FilterOperationsForNonString = ["=", "<>", "<", ">", "<=", ">=", "between"];
    static FilterOperationsForBoolean = ["=", "<>"];
    static StateStoring_Enabled = false;
    static UserRoleCCAdmin = "CC_Admin";
    static UserRoleCCUser = "CC_User";
    static UserRoleAppUser = "APP_User";
    static SourceEmailAddress = "software@leopardsystems.com.au";
    static GuidRegEx = /(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}/g;

    static LeopardDashboardColorBars = [
        "#4BAE4F", "#3E50B4", "#785447", "#F34235",
        "#2195F2", "#FE9700", "#8AC249", "#FE5622",
        "#02A8F3", "#CCDB39", "#E81D62", "#9B27AF",
        "#00BBD3", "#FEEA3B", "#6639B6", "#009587",
        "#FEC007", "#5F7C8A"];

    static StateStoring_Type = "localStorage";
    static ODataDocUrl = "http://docs.oasis-open.org/odata/odata/v4.0/errata03/os/complete/part2-url-conventions/odata-v4.0-errata03-os-part2-url-conventions-complete.html#_Toc453752357";
    static AWSCronExpressionUrl = "https://docs.aws.amazon.com/AmazonCloudWatch/latest/events/ScheduledEvents.html";

    static ReportViewerAppearanceShowTooltipsHelp = false;
    static ReportViewerToolbarShowAboutButton = false;
    static ReportViewerToolbarShowFileMenuAbout = false;
    static ReportViewerToolbarShowFileMenuHelp = false;
    static ReportViewerToolbarShowFileMenuInfo = false;
    static ReportViewerToolbarShowFileMenuExit = true;
    static ReportDesignerAppearanceShowTooltipsHelp = false;
    static ReportDesignerToolbarShowDesignButton = true;
    static ReportDesignerToolbarShowSaveButton = false;
    static ReportDesignerToolbarShowAboutButton = false;
    static ReportDesignerToolbarShowOpenButton = false;
    static ReportLicenseKey = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHnrYZEL/jPKDmxH+g9o1aDk8Gdux4BN69XrEhElyNfngzXXSY" +
        "p25CYhMiYuJAjIxfzQ6e6FuePCu8jOsprNn5zMfDeb+41f/nuVE1E5AF1Z4lN52mMD+3Bvb7yqMjM5ONitK/DcgNsS" +
        "ME893ReP0q3D6GFLL+Ox2pS5VPzLR4wk2sQ7jvzJsTkLNxchboQ7/8HdN4AtDRRBXzF2axk0T1divIquqE23ZJjpdM" +
        "lQReGsrvgQuAlt4C/BGicGte4c5JQ3rB5IELCbK06NbuiXOKzmGzmDcyAG8PNuK9vg8v1ulaNJV3+nez6KzMcxO8p9" +
        "IdGkxuAWe33oWbqi3Z218nvaFOLl7OObas7NwXz9lgfqqNkjFi3iMARpNzOX+Dco0Qx5wuxsSDhvba8VM+VFQGALfz" +
        "ogru3OzVkLetcIKWCem8/IglKcehmgbJXlBi8RMCyIeRczuTLpM/lnXsDoia56389lso91wfqpSHA3AuK0PbED4+Xa" +
        "nggE7GBX3gS9mnTZ6I78a+QBVlScBIQRPSjTYuoLmLDuEHzr767/+9jvYA==";

    static GridViewConfigVersion = 1;
    static ChartConfigVersion = 1;
    static DashboardConfigVersion = 1;
    static DataViewConfigVersion = 1;
    static LeftMenuItemVersion = 1;

    static ControlCentreVersion = "2.5.0.4";
    static ControlCentreReleaseTime = "2020-11-04 10:53:50";
    static ControlCentreReleaseBranch = "cubetst-rc3";
    static ControlCentreCommitID = "05d668120fa73f0d02569c1af38f85228e85542c";
    static Global_MenuItemSearchText = "";

    static Global_DashboardDataViewListeners = [];
    static Global_DashboardAutoSelectByDefault = true;
    static Global_DashboardAutoSelectInstance = null;
    static Global_WebsocketConnections = [];

    static SampleData_DocumentEditorDataSchema = {
        "type": "object",
        "properties": {
            "fullName": {
                "type": "string",
                "uiControl": "text",
                "field": {
                    "displayName": "Full name:"
                }
            },
            "dateOfBirth": {
                "type": "string",
                "format": "date",
                "uiControl": "date",
                "field": {
                    "displayName": "Date of birth:"
                }
            },
            "age": {
                "type": "number",
                "uiControl": "number",
                "field": {
                    "displayName": "Age:",
                    "readOnly": true
                }
            },
            "state": {
                "type": "string",
                "uiControl": "autocomplete",
                "field": {
                    "displayName": "State:",
                    "dataSource": [{
                        "id": "VIC", "name": "Victoria"
                    }, {
                        "id": "NSW", "name": "New South Wales"
                    }, {
                        "id": "NT", "name": "Northern Territory"
                    }, {
                        "id": "QLD", "name": "Queensland"
                    }, {
                        "id": "WA", "name": "Western Australia"
                    }, {
                        "id": "ACT", "name": "Australian Capital Territory"
                    }, {
                        "id": "TAS", "name": "Tasmania"
                    }, {
                        "id": "SA", "name": "South Australia"
                    }]
                }
            },
            "country": {
                "type": "string",
                "uiControl": "dropdown",
                "field": {
                    "displayName": "Country:",
                    "dataSource": [{
                        "id": "AU", "name": "Australia"
                    }, {
                        "id": "UK", "name": "United Kingdom"
                    }, {
                        "id": "US", "name": "United States"
                    }]
                }
            },
            "worklog": {
                "type": "array",
                "items": {
                    "type": "object",
                    "properties": {
                        "date": {
                            "type": "string",
                            "format": "date",
                            "uiControl": "date",
                            "field": {
                                "displayName": "Work date:"
                            }
                        },
                        "hours": {
                            "type": "number",
                            "uiControl": "number",
                            "field": {
                                "displayName": "Total hours:"
                            }
                        },
                        "department": {
                            "type": "string",
                            "uiControl": "dropdown",
                            "field": {
                                "displayName": "Department:",
                                "dataSource": [{
                                    "id": "IT", "name": "IT Department"
                                }, {
                                    "id": "SD", "name": "Service Department"
                                }, {
                                    "id": "AD", "name": "Accounting Department"
                                }, {
                                    "id": "OA", "name": "Office Administration"
                                }]
                            }
                        },
                        "notes": {
                            "type": "string",
                            "uiControl": "text",
                            "field": {
                                "displayName": "Notes:"
                            }
                        }
                    }
                }
            }
        }
    };

    static SampleData_DocumentEditorUISchema = {
        "type": "VerticalLayout",
        "elements": [
            {
                "type": "HorizontalLayout",
                "elements":
                    [{
                        "type": "Control",
                        "label": "Full name",
                        "scope": "#/properties/fullName"
                    }, {
                        "type": "Control",
                        "label": "Date of birth",
                        "scope": "#/properties/dateOfBirth"
                    }, {
                        "type": "Control",
                        "label": "Age",
                        "scope": "#/properties/age"
                    }]
            },
            {
                "type": "HorizontalLayout",
                "elements":
                    [{
                        "type": "Control",
                        "label": "State",
                        "scope": "#/properties/state"
                    }, {
                        "type": "Control",
                        "label": "Country",
                        "scope": "#/properties/country"
                    }]
            },
            {
                "type": "VerticalLayout",
                "elements":
                    [{
                        "type": "Control",
                        "scope": "#/properties/worklog"
                    }]
            }
        ]
    };

    static SampleData_DocumentEditorSampleData = {
        "fullName": "Citizen A",
        "dateOfBirth": "1985-02-01",
        "age": 36,
        "state": "VIC",
        "country": "AU",
        "worklog": [
            {
                "date": "2020-10-13",
                "hours": 7,
                "department": "IT",
                "notes": "Software development"
            },
            {
                "date": "2020-10-14",
                "hours": 8,
                "department": "SD",
                "notes": "Repair a broken device"
            }
        ]
    }

    static TooltipAnimationConfig = {
        show: {
            type: 'pop',
            from: {scale: 0},
            to: {scale: 1},
            delay: 1000
        },
        hide: null
    };

    static GridViewDefaultDefinition = {
        columnDefinition: [],
        adaptiveColumn: false,
        allowEditing: false,
        allowDragging: false,
        allowDropping: false,
        enableAutoRefresh: false,
        autoRefreshInterval: 30,
        customColumnOverallWidth: 200,
        showAutoRefreshSwitch: true,
        optimizePagerForLargeDataset: false,
        defaultPageSize: 10,
        explicitFilterOption: LeopardStaticUIConfig.GridView_ExplicitFilterOption,
        filterBuilderValue: LeopardStaticUIConfig.GridView_DefaultFilterValue,
        gridViewDefinitionVersion: LeopardStaticUIConfig.GridViewConfigVersion,
        columnResizingMode: LeopardStaticUIConfig.GridView_ColumnResizingMode
    };

    static ChartDefaultDefinition = {
        argAxisAllowDecimals: true,
        argAxisCustomizeText: undefined,
        argAxisEnableAutoBreak: false,
        argAxisEndOnTick: false,
        argAxisInvertAxis: false,
        argAxisMaxAutoBreakCount: 4,
        argAxisMaxValueMargin: undefined,
        argAxisPosition: "bottom",
        argAxisShowZero: true,
        argAxisType: undefined,
        argAxisValueType: "string",
        argAxisOverlappingBehavior: "stagger",
        argAxisTitle: "",
        chartAllowMouseWheel: true,
        chartAllowTouchGuestures: true,
        chartBarGroupPadding: 0.3,
        chartBarGroupWitdh: undefined,
        chartBgColorForExport: "#FFFFFF",
        chartContainerBgColor: "#FFFFFF",
        chartDragToZoom: false,
        chartEnableExport: false,
        chartMarginForExport: 10,
        chartMaxBubbleSize: 0.2,
        chartMinBubbleSize: 12,
        chartNegativeValuesAsZeroes: false,
        chartPaletteExtMode: "blend",
        chartPointSelectionMode: "onlyPoint",
        chartRedrawOnResize: true,
        chartResolveLabelOverlapping: "none",
        chartScrollbarOffset: 5,
        chartScrollbarPosition: "bottom",
        chartShowScrollbar: false,
        labelAlignment: "left",
        labelArgumentFormat: undefined,
        labelBgColor: undefined,
        labelHorizontalOffset: 0,
        labelLabelFormat: undefined,
        labelPosition: "outside",
        labelRotationAngle: 0,
        labelShowForZero: true,
        labelVerticalOffset: 0,
        labelVisibility: true,
        legendBgColor: undefined,
        legendColumnCount: undefined,
        legendColumnItemSpacing: 20,
        legendHorizontalAlignment: "left",
        legendHoverMode: "none",
        legendItemTextPosition: "right",
        legendItemsAlignment: "left",
        legendMargin: 10,
        legendMarkerSize: 20,
        legendOrientation: "horizontal",
        chartMarginTop: 0,
        chartMarginRight: 0,
        chartMarginLeft: 0,
        chartMarginBottom: 0,
        legendPaddingLeftRight: 10,
        legendPaddingTopBottom: 10,
        legendPosition: "outside",
        legendRowCount: undefined,
        legendRowItemSpacing: 8,
        legendVerticalAlignment: "bottom",
        tooltipCustomTextPart1: "none",
        tooltipCustomTextPart2: "none",
        labelCustomTextPart1: "none",
        labelCustomTextPart2: "none",
        legendVisibility: true,
        pointColor: undefined,
        pointCustomSymbol: "circle",
        pointHoverMode: "onlyPoint",
        pointSizeOfPoint: 12,
        pointVisibility: true,
        seriesAxisBinding: undefined,
        seriesHoverMode: "onlyPoint",
        seriesIgnoreEmptyPoint: true,
        seriesSelectionMode: "onlyPoint",
        seriesStyleBarPadding: undefined,
        seriesStyleBarWidth: undefined,
        seriesStyleCornerRadius: 0,
        seriesStyleDashStyle: "solid",
        seriesStyleInnerColor: undefined,
        tooltipArgumentFormat: undefined,
        tooltipArrowLength: 10,
        tooltipColor: "#FFFFFF",
        tooltipEnabled: false,
        tooltipLocation: "center",
        tooltipOpacity: undefined,
        tooltipPaddingLeftRight: 10,
        tooltipPaddingTopBottom: 10,
        valueAxisAllowDecimals: true,
        valueAxisCustomizeText: undefined,
        valueAxisEnableAutoBreak: false,
        valueAxisEndOnTick: false,
        valueAxisInvertAxis: false,
        valueAxisMaxAutoBreakCount: 4,
        valueAxisMaxValueMargin: undefined,
        valueAxisPosition: "left",
        valueAxisShowZero: true,
        valueAxisType: undefined,
        valueAxisValueType: "numeric",
        valueAxisHighAverage: undefined,
        valueAxisHighAverageText: "High Average",
        valueAxisLowAverage: undefined,
        valueAxisLowAverageText: "Low Average",
        valueAxisTitle: "",
        seriesSmallValueGroupingMode: "topN",
        seriesSmallValueGroupingTopCount: 8,
        chartPalette: "Soft Pastel",
        chartShowCrosshair: false,
        defaultVisualRange: 20,
        chartCrosshairWidth: 2,
        chartCrosshairColor: "#FE8000",
        chartCrosshairStyle: "solid",
        chartTitleFontSize: 20,
        enableAutoRefresh: false,
        autoRefreshInterval: 30,
        customColumnOverallWidth: 200,
        showAutoRefreshSwitch: true,
        chartDefinitionVersion: LeopardStaticUIConfig.ChartConfigVersion
    };
}

export default LeopardStaticUIConfig;
