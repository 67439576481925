import React from 'react';
import LDH from './LeopardDataHelper';
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";

class LeopardDataHelper extends React.Component {
    constructor(props) {
        super(props);

        momentDurationFormatSetup(moment);
        if (typeof moment.duration.fn.format !== "function") {
            console.log("moment-duration-format not supported.");
        }
        if (typeof moment.duration.format !== "function") {
            console.log("moment-duration-format not supported.");
        }
    }

    static GetColumnFromJSON(json) {
        if (!LeopardDataHelper.IsObjectNull(json) && json.length > 0) {
            let columns = [];
            for (let key in json[0]) {
                if (json[0].hasOwnProperty(key)) {
                    columns.push({columnName: key});
                }
            }
            return columns;
        }
        return [];
    }

    static IsObjectNull(obj) {
        return obj === undefined || obj === null;
    }

    static IsValueEmpty(value) {
        return LDH.IsObjectNull(value) || value === "" ||
            value.toString().trim() === "";
    }

    static ReplaceAll(str, search, replacement) {
        return str.split(search).join(replacement);
    }

    static IsValidEmailAddress(email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    static GetAllLocalStorageItemKeys() {
        let archive = [];
        let keys = Object.keys(localStorage);
        for (let i = 0; i < keys.length; i++) {
            archive.push(keys[i]);
        }
        return archive;
    }

    static DeleteLocalStorageById(id) {
        localStorage.removeItem(id);
    }

    static IsValidIPAddress(ipAddress) {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipAddress)) {
            return true;
        }
        return false;
    }

    static MapJsonObjectForGridViewData(dataItem, resultJSON, prefix) {
        let objectKeys = Object.keys(dataItem);
        for (let i = 0; i < objectKeys.length; i++) {
            let objectName = objectKeys[i];
            let objectValue = dataItem[objectKeys[i]];
            let childKeyLength = 0;
            if (typeof objectValue === 'object' && !LDH.IsObjectNull(objectValue)) {
                childKeyLength = Object.keys(objectValue).length;
            }
            if (typeof objectValue === 'object' && !LDH.IsObjectNull(objectValue) &&
                LDH.IsObjectNull(objectValue._value) && childKeyLength >= 5) {
                this.MapJsonObjectForGridViewData(objectValue, resultJSON, objectName);
            } else {
                if (dataItem.hasOwnProperty(objectName) && !LDH.IsObjectNull(objectValue) &&
                    typeof objectValue.getMonth === 'function') {
                    objectValue = LDH.ConvertUTCDateToLocalDate(objectValue);
                }
                if (LDH.IsValueEmpty(prefix) === false) {
                    resultJSON[prefix + "#" + objectName] = objectValue;
                } else {
                    resultJSON[objectName] = objectValue;
                }
            }
        }
    }

    static GetTableAndColumnNameByCustomQueryTableSchema(gridDefinition, tableName, columnName) {
        if (LDH.IsObjectNull(gridDefinition.dataSourceCustomQueryTableSchema) === false &&
            LDH.IsValueEmpty(gridDefinition.dataSourceCustomQueryTableSchema) === false &&
            LDH.IsValueEmpty(columnName) === false) {
            let tableSchemas = gridDefinition.dataSourceCustomQueryTableSchema.split("|");
            for (let m = 0; m < tableSchemas.length; m++) {
                if (columnName.indexOf("#") === -1 && tableSchemas[m].indexOf("{") === -1 &&
                    tableSchemas[m].indexOf("}") === -1) {
                    tableName = tableSchemas[m].trim();
                    break;
                }
                let cName = "{" + columnName.split("#")[0] + "}";
                if (columnName.indexOf("#") > -1 && tableSchemas[m].indexOf(cName) > -1) {
                    let startIndex = tableSchemas[m].indexOf("}") + 1;
                    let endIndex = tableSchemas[m].length;
                    tableName = tableSchemas[m].substring(startIndex, endIndex);
                    columnName = columnName.split("#")[1];
                    break;
                }
            }
        }
        return {tableName, columnName};
    }

    static ConvertJsonDataNullOrEmptyValues(jsonData, isCloned) {
        if (isCloned === undefined) isCloned = false;
        if (LDH.IsObjectNull(jsonData)) return jsonData;
        let clonedJsonData = isCloned ? jsonData : LDH.DeepClone(jsonData);
        for (let key in clonedJsonData) {
            if (clonedJsonData.hasOwnProperty(key)) {
                if (clonedJsonData[key] === undefined) {
                    clonedJsonData[key] = "[UNDEFINED]";
                } else if (LDH.IsObjectNull(clonedJsonData[key])) {
                    clonedJsonData[key] = "[NULL]";
                } else if (clonedJsonData[key] === "") {
                    clonedJsonData[key] = "[EMPTY]";
                }
                if (typeof clonedJsonData[key] === 'object') {
                    LDH.ConvertJsonDataNullOrEmptyValues(clonedJsonData[key], true);
                }
            }
        }
        return clonedJsonData;
    }

    static UnconvertJsonDataNullOrEmptyValues(jsonData, isCloned) {
        if (isCloned === undefined) isCloned = false;
        let clonedJsonData = isCloned ? jsonData : LDH.DeepClone(jsonData);
        for (let key in clonedJsonData) {
            if (clonedJsonData.hasOwnProperty(key)) {
                if (clonedJsonData[key] === "[UNDEFINED]") {
                    clonedJsonData[key] = undefined;
                } else if (clonedJsonData[key] === "[NULL]") {
                    clonedJsonData[key] = null;
                } else if (clonedJsonData[key] === "[EMPTY]") {
                    clonedJsonData[key] = "";
                }
                if (typeof clonedJsonData[key] === 'object') {
                    LDH.UnconvertJsonDataNullOrEmptyValues(clonedJsonData[key], true);
                }
            }
        }
        return clonedJsonData;
    }

    static IsValueTrue(value) {
        if (LDH.IsObjectNull(value) || value === "") {
            return false;
        }
        return value.toString().toLowerCase() === "true";
    }

    static ConvertGuidToUpperCaseFromJSON(jsonData) {
        let keys = Object.keys(jsonData);
        for (let i = 0; i < keys.length; i++) {
            let value = jsonData[keys[i]];
            if (!LDH.IsObjectNull(LeopardStaticUIConfig.GuidRegEx.exec(value))) {
                jsonData[keys[i]] = value.toString().toUpperCase();
            }
        }
        return jsonData;
    }

    static GetCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    static ConvertDateToYYYYMMDD = (date) => {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    };

    static DeepClone(obj) {
        if (this.IsObjectNull(obj)) return null;
        return JSON.parse(JSON.stringify(obj));
    }

    static GenerateGuid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }

        return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
    }

    static ConvertUTCDateToLocalDate(dateObj) {
        let offset = dateObj.getTimezoneOffset() / 60;
        let hours = dateObj.getHours();
        dateObj.setHours(hours - offset);
        return dateObj;
    }

    static ConvertLocalDateToUTCDate(dateObj) {
        let result = new Date(dateObj.toUTCString().slice(0, -3));
        result.convertedToUtc = true;
        return result;
    }

    static GetLocalISODateString(localDate) {
        let tzoffset = localDate.getTimezoneOffset() * 60000;
        let localISOTime = (new Date(localDate - tzoffset)).toISOString().slice(0, -1);
        return localISOTime + "Z";
    }

    static GenerateGuidWithDashes() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }

        return (s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4()).toUpperCase();
    }

    static IsIsoDateFormat(str) {
        try {
            if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) {
                return false;
            }
            let d = new Date(str);
            return d.toISOString() === str;
        } catch (error) {
            return false;
        }
    }

    static TraverseJSONObject(jsonObj) {
        if (!LDH.IsObjectNull(jsonObj) && typeof jsonObj == "object") {
            Object.entries(jsonObj).forEach(([key, value]) => {
                jsonObj[key] = LDH.TraverseJSONObject(value);
            });
            return jsonObj
        } else {
            try {
                let date = new Date(jsonObj);
                if (!LDH.IsValueEmpty(date) &&
                    LDH.IsIsoDateFormat(jsonObj)) {
                    jsonObj = date;
                }
            } catch (error) {
                return jsonObj
            }
        }
        return jsonObj
    }

    static ParseDevExtremeFilterString(filterValue) {
        if (this.IsObjectNull(filterValue)) return null;
        let clonedValue = LDH.DeepClone(filterValue);
        try {
            return LDH.TraverseJSONObject(filterValue);
        } catch (error) {
            return clonedValue;
        }
    }

    static AddLeadingZero(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }

    static ValidateRegEx(regex, data) {
        try {
            if (regex === "[required]") {
                return !LDH.IsValueEmpty(data);
            }
            let re = new RegExp(regex);
            return re.test(data);
        } catch (error) {
            return false;
        }
    }

    static ConvertCsvToJSON(csv) {
        let lines = csv.split("\n");
        let result = [];
        let headers = lines[0].split(",");
        for (let i = 1; i < lines.length - 1; i++) {
            let obj = {};
            let currentline = lines[i].split(",");
            for (let j = 0; j < headers.length; j++) {
                if (LDH.IsValueEmpty(headers[j])) {
                    continue;
                }
                obj[headers[j].trim()] = currentline[j].trim();
            }
            result.push(obj);
        }
        return result;
    }

    static ReadTextFile = function (e, callback) {
        try {
            let input = e.target;
            let reader = new FileReader();
            let content = "";

            reader.onload = function () {
                content = reader.result;
                return callback(content);
            };
            if (LDH.IsObjectNull(input.files) ||
                input.files.length === 0) {
                return;
            }
            reader.readAsText(input.files[0]);
        } catch (error) {

        }
    };

    static GetClientSideQuery(query, variableName) {
        let id = LDH.GenerateGuid();
        return "var val_" + id + "=" + variableName + ";try{" + query + "val_" + id + "=null}" +
            "catch(error){" + variableName + " = val_" + id + ";window.__callback();}";
    }

    static IsTimeoutReceivedFromAPIGateway(data) {
        let errorTexts = ["status code 504", "ETIMEDOUT", "timed out", "server error"];
        let errorMessage = "";

        if (!LDH.IsObjectNull(data) && typeof data === "object" &&
            !LDH.IsObjectNull(data.errorMessage) && !LDH.IsValueEmpty(data.errorMessage) &&
            typeof data.errorMessage !== "object") {
            errorMessage = data.errorMessage;
        } else if (!LDH.IsObjectNull(data) && typeof data === "object" &&
            !LDH.IsObjectNull(data.response) && typeof data.response === "object" &&
            !LDH.IsObjectNull(data.response.data) &&
            typeof data.response.data === "object" &&
            !LDH.IsObjectNull(data.response.data.message) &&
            !LDH.IsValueEmpty(data.response.data.message) &&
            typeof data.response.data.message !== "object") {
            errorMessage = data.response.data.message;
        }

        if (LDH.IsValueEmpty(errorMessage) === false) {
            for (let i = 0; i < errorTexts.length; i++) {
                if (errorMessage.indexOf(errorTexts[i]) !== -1) {
                    return true;
                }
            }
        }
        if (!LDH.IsObjectNull(data) && !LDH.IsObjectNull(data.error) &&
            typeof data.error === "object" &&
            !LDH.IsObjectNull(data.error.httpStatus) &&
            data.error.httpStatus === 504) {
            return true;
        }
        if (!LDH.IsObjectNull(data) && !LDH.IsObjectNull(data.response) &&
            typeof data.response === "object" &&
            !LDH.IsObjectNull(data.response.status) &&
            data.response.status === 504) {
            return true;
        }
        return false;
    }

    static GetTodayUtc() {
        let today = new Date();
        return (new Date(today.getFullYear(), today.getMonth(), today.getDate())).toISOString();
    }

    static GetTodayLocal() {
        return moment().format('YYYY-MM-DD') + " 00:00:00";
    }

    static GetNowUtc() {
        let today = new Date();
        return today.toISOString();
    }

    static GetIncludedColumns(limitedDataColumns, fullColumns) {
        let filterColumns = [];
        let fullColumnsWithoutCustom = [];
        if (LDH.IsObjectNull(fullColumns)) fullColumns = [];

        for (let i = 0; i < fullColumns.length; i++) {
            if (fullColumns[i].startsWith("_")) {
                continue;
            }
            fullColumnsWithoutCustom.push(fullColumns[i]);
        }

        if (LDH.IsObjectNull(limitedDataColumns) || limitedDataColumns.length === 0) {
            return fullColumnsWithoutCustom;
        }
        if (!LDH.IsObjectNull(fullColumnsWithoutCustom) && fullColumnsWithoutCustom.length > 0) {
            for (let i = 0; i < fullColumnsWithoutCustom.length; i++) {
                let found = false;
                for (let j = 0; j < limitedDataColumns.length; j++) {
                    if (fullColumnsWithoutCustom[i] === limitedDataColumns[j]) {
                        found = true;
                        break;
                    }
                }
                if (found === false) {
                    filterColumns.push(fullColumnsWithoutCustom[i]);
                }
            }
        }
        return filterColumns;
    }

    static AddHoursToDateUtc(isoDate, hours) {
        let result = new Date(isoDate);
        result.setHours(result.getHours() + hours);
        return result.toISOString();
    }

    static AddMonthsToDate(dateObj, months) {
        let tempDate = dateObj.getDate();
        dateObj.setMonth(dateObj.getMonth() + +months);
        if (dateObj.getDate() !== tempDate) {
            dateObj.setDate(0);
        }
        return dateObj;
    }

    static AddHoursToDateLocal(localDate, hours) {
        let result = new Date(localDate);
        result.setHours(result.getHours() + hours);

        let datePart = LDH.ConvertDateToYYYYMMDD(result);
        let hour = result.getHours();
        if (hour < 10) hour = "0" + hour;
        return datePart + "T" + hour + ":00:00";
    }

    static GenerateRandomNumber(minValue, maxValue) {
        return Math.floor((Math.random() * maxValue) + minValue);
    }

    static FilterMacro(dataString) {
        let today = LDH.GetTodayUtc();
        let todayLocal = LDH.GetTodayLocal();
        let orgId = window.userProfile.Parent.toLowerCase();

        if (LDH.IsValueEmpty(dataString)) dataString = "";
        dataString = LDH.ReplaceAll(dataString, "{user-group-id}", orgId);

        dataString = LDH.ReplaceAll(dataString, "{today}", today);
        dataString = LDH.ReplaceAll(dataString, "{today-local}", todayLocal);
        dataString = LDH.ReplaceAll(dataString, "{yesterday}", "{day(-1)}");
        dataString = LDH.ReplaceAll(dataString, "{tomorrow}", "{day(1)}");

        dataString = LDH.ReplaceAll(dataString, "{next3days}", "{day(3)}");
        dataString = LDH.ReplaceAll(dataString, "{next7days}", "{day(7)}");
        dataString = LDH.ReplaceAll(dataString, "{next14days}", "{day(14)}");
        dataString = LDH.ReplaceAll(dataString, "{next30days}", "{day(30)}");

        dataString = LDH.ReplaceAll(dataString, "{last3days}", "{day(-3)}");
        dataString = LDH.ReplaceAll(dataString, "{last7days}", "{day(-7)}");
        dataString = LDH.ReplaceAll(dataString, "{last14days}", "{day(-14)}");
        dataString = LDH.ReplaceAll(dataString, "{last30days}", "{day(-30)}");

        for (let i = -10000; i < 10000; i++) {
            dataString = LDH.DateMacroConverter(dataString, i);
        }
        return dataString;
    }

    static GetImageListFromResult(result, itemId) {
        let items = [];
        if (!LDH.IsObjectNull(result) && !LDH.IsObjectNull(result.message) &&
            result.message.length > 0) {
            for (let i = 0; i < result.message.length; i++) {
                if (result.message[i].DisplayType === "TN" &&
                    result.message[i].ContentType === "image/jpeg") {
                    let originalImageUrl = "";

                    for (let j = 0; j < result.message.length; j++) {
                        if (result.message[j].FileName === result.message[i].FileName &&
                            result.message[j].DisplayType === "IMG" &&
                            result.message[j].ContentType === "image/jpeg") {
                            originalImageUrl = result.message[j].Url;
                            break;
                        }
                    }

                    items.push({
                        imageUrl: result.message[i].Url,
                        id: LDH.GenerateGuid(),
                        itemId,
                        originalImageUrl
                    });
                }
            }
        }
        return items;
    }

    static ParseGuidForODataQuery(dataString, columnName) {
        let guidMatches = [];
        let match;
        while ((match = !LDH.IsObjectNull(LeopardStaticUIConfig.GuidRegEx.exec(dataString)))) {
            guidMatches.push(match[0]);
        }
        for (let i = 0; i < guidMatches.length; i++) {
            let value = guidMatches[i];
            dataString = LDH.ReplaceAll(dataString, "'" + value + "'", value);
            dataString = LDH.ReplaceAll(dataString, value, "'" + value + "'");

            if (!LDH.IsObjectNull(columnName) && !LDH.IsValueEmpty(columnName)) {
                dataString = LDH.ReplaceAll(dataString, columnName + " eq '" + value + "'",
                    "(startswith(tolower(" + columnName + "),'" + value + "'))");
            }
        }
        return dataString;
    }

    static DateMacroConverter(dataString, index) {
        let today = LDH.GetTodayUtc();
        let todayLocal = LDH.GetTodayLocal();

        if (dataString.indexOf("{day(" + index + ")}") > -1) {
            let result = LDH.AddHoursToDateUtc(today, index * 24);
            dataString = LDH.ReplaceAll(dataString, "{day(" + index + ")}", result);
        }
        if (dataString.indexOf("{day-local(" + index + ")}") > -1) {
            let result = LDH.AddHoursToDateLocal(todayLocal, index * 24);
            dataString = LDH.ReplaceAll(dataString, "{day-local(" + index + ")}", result);
        }
        if (dataString.indexOf("{hour(" + index + ")}") > -1) {
            let result = LDH.AddHoursToDateUtc(today, index);
            dataString = LDH.ReplaceAll(dataString, "{hour(" + index + ")}", result);
        }
        if (dataString.indexOf("{hour-local(" + index + ")}") > -1) {
            let result = LDH.AddHoursToDateLocal(todayLocal, index);
            dataString = LDH.ReplaceAll(dataString, "{hour-local(" + index + ")}", result);
        }
        if (dataString.indexOf("{day-us(" + index + ")}") > -1) {
            let result = LDH.AddHoursToDateUtc(today, index * 24);
            result = LDH.ConvertDateToYYYYMMDD(result);
            dataString = LDH.ReplaceAll(dataString, "{day-us(" + index + ")}", result);
        }
        if (dataString.indexOf("{hour-us(" + index + ")}") > -1) {
            let result = LDH.AddHoursToDateUtc(today, index);
            result = LDH.ConvertDateToYYYYMMDD(result);
            dataString = LDH.ReplaceAll(dataString, "{hour-us(" + index + ")}", result);
        }
        if (dataString.indexOf("{firstday-month-start(" + index + ")}") > -1) {
            let dateObj = new Date();
            let firstDay = new Date(dateObj.getFullYear(), dateObj.getMonth() + index, 1);
            let result = LDH.AddHoursToDateUtc(firstDay, 0);
            dataString = LDH.ReplaceAll(dataString, "{firstday-month-start(" + index + ")}", result);
        }
        if (dataString.indexOf("{firstday-month-end(" + index + ")}") > -1) {
            let dateObj = new Date();
            let firstDay = new Date(dateObj.getFullYear(), dateObj.getMonth() + index, 1, 23, 59, 59, 999);
            let result = LDH.AddHoursToDateUtc(firstDay, 0);
            dataString = LDH.ReplaceAll(dataString, "{firstday-month-end(" + index + ")}", result);
        }
        if (dataString.indexOf("{lastday-month-start(" + index + ")}") > -1) {
            let dateObj = new Date();
            let lastDay = new Date(dateObj.getFullYear(), dateObj.getMonth() + 1 + index, 0);
            let result = LDH.AddHoursToDateUtc(lastDay, 0);
            dataString = LDH.ReplaceAll(dataString, "{lastday-month-start(" + index + ")}", result);
        }
        if (dataString.indexOf("{lastday-month-end(" + index + ")}") > -1) {
            let dateObj = new Date();
            let lastDay = new Date(dateObj.getFullYear(), dateObj.getMonth() + 1 + index, 0, 23, 59, 59, 999);
            let result = LDH.AddHoursToDateUtc(lastDay, 0);
            dataString = LDH.ReplaceAll(dataString, "{lastday-month-end(" + index + ")}", result);
        }
        return dataString;
    }

    static ConvertArrayMacroToString(dataString, arrayData, columnDefinition) {
        let columns = Object.keys(arrayData);
        for (let i = 0; i < columns.length; i++) {
            let name = columns[i];
            let value = arrayData[name];

            if (LDH.IsObjectNull(columnDefinition) === false) {
                for (let j = 0; j < columnDefinition.length; j++) {
                    if ((LDH.IsObjectNull(columnDefinition[j].columnType) ||
                        columnDefinition[j].columnType === "string") &&
                        name === columnDefinition[j].columnName &&
                        LDH.IsValueEmpty(value) === true) {
                        value = '';
                    } else if ((LDH.IsObjectNull(columnDefinition[j].columnType) ||
                        columnDefinition[j].columnType === "guid") &&
                        name === columnDefinition[j].columnName &&
                        LDH.IsValueEmpty(value) === true) {
                        value = '';
                    } else if ((LDH.IsObjectNull(columnDefinition[j].columnType) ||
                        columnDefinition[j].columnType === "datetime") &&
                        name === columnDefinition[j].columnName &&
                        LDH.IsValueEmpty(value) === true) {
                        value = this.GetTodayUtc();
                    } else if ((LDH.IsObjectNull(columnDefinition[j].columnType) ||
                        columnDefinition[j].columnType === "date") &&
                        name === columnDefinition[j].columnName &&
                        LDH.IsValueEmpty(value) === true) {
                        value = this.GetTodayUtc();
                    } else if ((LDH.IsObjectNull(columnDefinition[j].columnType) ||
                        columnDefinition[j].columnType === "number") &&
                        name === columnDefinition[j].columnName &&
                        LDH.IsValueEmpty(value) === true) {
                        value = 0;
                    } else if ((LDH.IsObjectNull(columnDefinition[j].columnType) ||
                        columnDefinition[j].columnType === "boolean") &&
                        name === columnDefinition[j].columnName &&
                        LDH.IsValueEmpty(value) === true) {
                        value = 0;
                    }
                }
            }
            if (LDH.IsValueEmpty(value)) value = '';
            dataString = LDH.ReplaceAll(dataString, "{" + name + "}", value);
        }
        return dataString;
    }

    static GetOrganizationIdFromUserProfile(userProfile) {
        if (LDH.IsObjectNull(userProfile) === true) {
            return null;
        }
        if (LDH.IsObjectNull(userProfile.data) === true) {
            if (!LDH.IsObjectNull(userProfile.Parent)) {
                return userProfile.Parent;
            }
            return null;
        }
        return userProfile.data.Parent;
    }

    static GetUserIdFromUserProfile(userProfile) {
        if (LDH.IsObjectNull(userProfile) === true) {
            return null;
        }
        if (LDH.IsObjectNull(userProfile.data) === true) {
            if (!LDH.IsObjectNull(userProfile.ID)) {
                return userProfile.ID;
            }
            return null;
        }
        return userProfile.data.ID;
    }

    static IsControlCentreLatestVersion(data) {
        let isLatestVersion = true;
        let ccVersion = LeopardStaticUIConfig.ControlCentreVersion;

        if (LDH.IsObjectNull(data) || data.length === 0 || data === [] ||
            ccVersion.indexOf("BUILD_NUMBER_PLACEHOLDER") > -1) {
            isLatestVersion = true;
        } else {
            let remoteVersion = parseInt(LDH.ReplaceAll(data.currentVersion, ".", ""));
            let localVersion = ccVersion;

            localVersion = parseInt(LDH.ReplaceAll(localVersion, ".", ""));
            return localVersion >= remoteVersion;
        }
        return isLatestVersion;
    }

    static IsUpdateControlCentreVersionRequired(data) {
        let isRequired;
        let ccVersion = LeopardStaticUIConfig.ControlCentreVersion;

        if ((LDH.IsObjectNull(data) || data.length === 0) &&
            ccVersion.indexOf("BUILD_NUMBER_PLACEHOLDER") === -1) {
            isRequired = true;
        } else if (ccVersion.indexOf("BUILD_NUMBER_PLACEHOLDER") > -1) {
            isRequired = false;
        } else {
            let remoteVersion = parseInt(LDH.ReplaceAll(data.currentVersion, ".", ""));
            let localVersion = ccVersion;
            localVersion = parseInt(LDH.ReplaceAll(localVersion, ".", ""));
            isRequired = localVersion > remoteVersion;
        }
        return isRequired;
    }

    static GetRelationshipsByDashboardItemId = (relationships, dashboardItemId) => {
        let resultList = [];
        for (let i = 0; i < relationships.length; i++) {
            if (relationships[i].parentDataViewId.split(":")[0] === dashboardItemId) {
                resultList.push(relationships[i]);
            }
            if (relationships[i].childDataViewId.split(":")[0] === dashboardItemId) {
                resultList.push(relationships[i]);
            }
        }
        return resultList;
    };

    static ValidateDashboardRelationships = (relationships, requestPId, requestCId, requestRId) => {
        let parentRIds = [];
        if (LDH.IsValueEmpty(requestPId) || LDH.IsValueEmpty(requestCId)) return true;
        if (requestPId === requestCId) return false;

        for (let w = 0; w < relationships.length; w++) {
            if (relationships[w].relationshipId === requestRId) {
                relationships[w].parentDataViewId = requestPId;
                relationships[w].childDataViewId = requestCId;
            }
        }

        for (let i = 0; i < relationships.length; i++) {
            if (relationships[i].childDataViewId === requestCId &&
                relationships[i].relationshipId !== requestRId) {
                return false;
            }

            if (relationships[i].childDataViewId === requestCId &&
                relationships[i].parentDataViewId === requestPId &&
                requestRId !== relationships[i].relationshipId) {
                return false;
            }

            for (let j = 0; j < relationships.length; j++) {
                if (relationships[i].childDataViewId === relationships[j].parentDataViewId &&
                    !LDH.IsValueEmpty(relationships[j].parentDataViewId)) {

                    let foundParent = false;
                    for (let m = 0; m < parentRIds.length; m++) {
                        if (parentRIds[m] === relationships[i].relationshipId) {
                            foundParent = true;
                        }
                    }
                    if (foundParent === false) {
                        parentRIds.push(relationships[i].relationshipId);
                    }

                    let result = this.FindDashboardRelationshipItem(relationships, requestPId,
                        requestCId, requestRId, relationships[j].relationshipId,
                        parentRIds);

                    if (result === "not-found") {
                        parentRIds = [];
                    } else {
                        return result;
                    }
                }
            }
        }
        return true;
    };

    static FindDashboardRelationshipItem = (relationships, requestPId, requestCId,
                                            requestRId, currentRId, parentRIds) => {
        let currentChildDataViewId = "";
        if (currentRId === requestRId) {
            currentChildDataViewId = requestCId;
        } else {
            for (let i = 0; i < relationships.length; i++) {
                if (relationships[i].relationshipId === currentRId) {
                    currentChildDataViewId = relationships[i].childDataViewId;
                }
            }
        }

        for (let k = 0; k < parentRIds.length; k++) {
            for (let n = 0; n < relationships.length; n++) {
                if (relationships[n].relationshipId === parentRIds[k] &&
                    relationships[n].parentDataViewId === currentChildDataViewId) {
                    return false;
                }
            }
        }

        for (let w = 0; w < relationships.length; w++) {
            if (relationships[w].childDataViewId === currentChildDataViewId &&
                relationships[w].relationshipId !== currentRId) {
                return false;
            }
        }

        for (let j = 0; j < relationships.length; j++) {
            if (currentChildDataViewId === relationships[j].parentDataViewId &&
                !LDH.IsValueEmpty(relationships[j].parentDataViewId)) {
                parentRIds.push(currentRId);

                return this.FindDashboardRelationshipItem(relationships, requestPId,
                    requestCId, requestRId, relationships[j].relationshipId,
                    parentRIds);
            }
        }
        return "not-found";
    };

    static SetRelationshipColorBar = (relationships) => {
        if (LDH.IsObjectNull(relationships)) return [];
        let colorBars = LeopardStaticUIConfig.LeopardDashboardColorBars;
        let colorIndex = 0;
        let coloredParentDataViewIds = [];

        for (let n = 0; n < relationships.length; n++) {
            if (LDH.IsValueEmpty(relationships[n].parentDataViewId) ||
                LDH.IsValueEmpty(relationships[n].childDataViewId)) {
                continue;
            }
            let foundColor = null;
            let foundLevel = null;
            let pId = relationships[n].parentDataViewId;

            for (let k = 0; k < coloredParentDataViewIds.length; k++) {
                if (coloredParentDataViewIds[k].pId === pId) {
                    foundColor = coloredParentDataViewIds[k].color;
                    foundLevel = coloredParentDataViewIds[k].level;
                    break;
                }
            }
            if (!LDH.IsObjectNull(foundColor)) {
                relationships[n].color = foundColor;
                relationships[n].level = foundLevel;
            }

            if ((LDH.IsObjectNull(relationships[n].color) ||
                LDH.IsValueEmpty(relationships[n].color)) &&
                LDH.IsValueEmpty(foundColor)) {
                if (colorIndex > colorBars.length) {
                    colorIndex = 0;
                }

                let color = colorBars[colorIndex];
                let level = 1;

                for (let j = 0; j < relationships.length; j++) {
                    let cId = relationships[n].childDataViewId;
                    if (relationships[j].parentDataViewId === cId &&
                        !LDH.IsObjectNull(relationships[j].color) &&
                        !LDH.IsValueEmpty(relationships[j].color)) {
                        color = relationships[j].color;
                        break;
                    }
                }
                relationships[n].color = color;
                relationships[n].level = level;
                coloredParentDataViewIds.push({
                    pId, color, level
                });
                colorIndex++;
            }

            let rsId = relationships[n].relationshipId;
            LDH.SetRelationshipColorForChildren(rsId, relationships);
        }
        return relationships;
    };

    static SetRelationshipColorForChildren = (currentRelationshipId, relationships) => {
        for (let i = 0; i < relationships.length; i++) {
            if (currentRelationshipId !== relationships[i].relationshipId) {
                continue;
            }
            let childDataViewId = relationships[i].childDataViewId;

            for (let j = 0; j < relationships.length; j++) {
                if (relationships[j].parentDataViewId === childDataViewId) {
                    relationships[j].color = relationships[i].color;

                    relationships[j].level = LDH.IsObjectNull(relationships[i].level) ||
                    LDH.IsValueEmpty(relationships[i].level) ? 1 :
                        relationships[i].level + 1;

                    let rsId = relationships[j].relationshipId;
                    LDH.SetRelationshipColorForChildren(rsId, relationships);
                }
            }
        }
    };

    static SetPostProcessResultForDataViewInstance = (instance, isDataView, hasCustomQueryParams,
                                                      dashboardItemId) => {
        let relationships = instance.option("relationships");
        if (!isDataView && (LDH.IsObjectNull(instance.option("customQueryParams")) ||
            LDH.IsValueEmpty(instance.option("customQueryParams"))) &&
            hasCustomQueryParams) {
            if (!LDH.IsObjectNull(relationships) && relationships.length > 0) {
                let foundParent = false;
                for (let v = 0; v < relationships.length; v++) {
                    if (relationships[v].childDataViewId.split(":")[0] === dashboardItemId &&
                        relationships[v].parentDataViewId.split(":")[0] !== dashboardItemId) {
                        foundParent = true;
                    }
                }
                if (foundParent) instance.option("setBlankPostProcess", true);
            } else {
                instance.option("setBlankPostProcess", false);
            }
        }
    };

    static APIEndpointAdapter = () => {
        if (window.location.hostname !== "localhost" &&
            LDH.IsValidIPAddress(window.location.hostname) === false) {
            return "https://api." + window.location.hostname;
        }
        return "https://api.cubest2.leopardcube.com.au";
    };
}

export default LeopardDataHelper;
