import React, {Component} from 'react';
import {Button, Popup} from "devextreme-react";
import LDH from '../helpers/LeopardDataHelper';
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";
import DataGrid, {ColumnFixing, Pager, Paging} from 'devextreme-react/data-grid';
import {ProgressBar} from 'devextreme-react/progress-bar';
import {alert} from "devextreme/ui/dialog";
import $ from 'jquery';
import LRH from "../helpers/LeopardReactHelper";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";

class LeopardDataImportPopup extends Component {
    // =======================================================================
    // ============================ Constructor ==============================
    // =======================================================================
    constructor(props) {
        super(props);

        this.state = {
            gridViewData: null,
            popupVisible: false,
            popupTitle: null,
            contentHeight: "",
            popupWidth: "90%",
            popupHeight: "90%",
            popupPosition: {
                my: 'center',
                at: 'center',
                of: window
            }
        };

        this.uiObjectInstances = [];
        this.processAborted = false;
        this.processFinished = false;
        this.instanceInitialized = false;
        this.disposingAllInstances = false;
    };

    // =======================================================================
    // ========================= UI Initialization ===========================
    // =======================================================================
    componentWillUnmount = () => {
        this.disposingAllInstances = true;
        LRH.DisposeUIInstancesFromList(this.uiObjectInstances);
    };

    initializeUIComponents = () => {
        this.uiObjectInstances["progress"].instance.option("visible", false);
        this.uiObjectInstances["progress"].instance.option("value", 0);
        this.uiObjectInstances["buttonCancel"].instance.option("visible", true);
        this.uiObjectInstances["buttonImport"].instance.option("disabled", true);
        this.uiObjectInstances["buttonImport"].instance.option("visible", true);
        this.uiObjectInstances["buttonAbort"].instance.option("visible", false);
        this.uiObjectInstances["buttonAbort"].instance.option("disabled", false);
        this.uiObjectInstances["buttonFinish"].instance.option("visible", false);
        this.uiObjectInstances["buttonExport"].instance.option("visible", false);

        let $stats = $("#leopard_dataimport_stats_" + this.props.dataViewId);
        $(".total", $stats).text(0);
        $(".processed", $stats).text(0);
        $(".warning", $stats).text(0);
        $(".failed", $stats).text(0);

        let loadPanel = this.uiObjectInstances["gridview"].instance.option("loadPanel");
        loadPanel.enabled = true;

        this.processAborted = false;
        this.processFinished = false;
        $(".leopard-import .leopard-spinner", $stats).hide();
        $(".leopard-text-color.green", $stats).hide();
        $(".leopard-text-color.orange", $stats).hide();
        $(".leopard-text-color.red", $stats).hide();
    };

    finishUIComponents = () => {
        this.uiObjectInstances["buttonAbort"].instance.option("visible", false);
        this.uiObjectInstances["buttonFinish"].instance.option("visible", true);
        this.uiObjectInstances["buttonExport"].instance.option("visible", true);
        this.uiObjectInstances["progress"].instance.option("visible", false);
        this.uiObjectInstances["progress"].instance.option("value", 0);

        let $statusbar = $("#leopard_dataimport_statusbar_" + this.props.dataViewId);
        $(".leopard-import .leopard-spinner", $statusbar).hide();

        this.processFinished = true;
        window.Global_PopupTempObjectInstance.option("showCloseButton", true);
    };

    // =======================================================================
    // =========================== Event Handlers ============================
    // =======================================================================
    popupOnHide = (e) => {
        let that = this;
        window.Global_PopupTempData = null;
        this.uiObjectInstances = [];

        this.setState({
                gridViewData: "",
                popupWidth: "90%",
                popupHeight: "90%",
                popupPosition: {
                    my: 'center',
                    at: 'center',
                    of: window
                }
            },
            function () {
                if (that.processFinished === true) {
                    that.props.popupOnClosed(e);
                }
                that.initializeUIComponents();
            });
    };

    popupOnShowing = () => {
        let title = this.props.popupTitle;
        let cheight = this.props.contentHeight;
        this.uiObjectInstances = [];

        this.setState({
            popupTitle: title, contentHeight: cheight
        });
    };

    popupOnShown = () => {
        let that = this;
        let data = window.Global_PopupTempData.data;
        let definition = window.Global_PopupTempData.definition;
        this.initializeUIComponents();

        if (!LDH.IsObjectNull(data) && data.length > 0) {
            let allowImport = true;

            for (let i = 0; i < data.length; i++) {
                let keys = Object.keys(data[i]);
                let validated = true;
                let errors = "";
                data[i] = LDH.ConvertGuidToUpperCaseFromJSON(data[i]);

                for (let j = 0; j < keys.length; j++) {
                    let key = keys[j];
                    let value = data[i][key];

                    for (let k = 0; k < definition.columnDefinition.length; k++) {
                        let column = definition.columnDefinition[k];

                        if (!LDH.IsObjectNull(column.validationRules) &&
                            !LDH.IsValueEmpty(column.validationRules) &&
                            column.columnName === key) {
                            let rules = JSON.parse(column.validationRules);

                            for (let v = 0; v < rules.length; v++) {
                                if (!LDH.IsObjectNull(rules[v].regex) &&
                                    !LDH.IsValueEmpty(rules[v].regex)) {
                                    if (!LDH.ValidateRegEx(rules[v].regex, value)) {
                                        validated = false;
                                        allowImport = false;
                                        errors +=
                                            "<div style='padding: 2px'>" +
                                            "   <span class='leopard-text-color red'>" +
                                            "       <b>" + key + ": </b>" +
                                            "   </span>" +
                                            "   <span>" + rules[v].error + "</span>" +
                                            "</div>"
                                    }
                                }
                            }
                        }
                    }
                }

                data[i]["Pre-check"] = validated ? "PASS" : "FAIL";
                data[i]["Processed"] = "";
                data[i]["ServerErrors"] = "";
                data[i]["ValidationErrors"] = errors;
            }

            let url = definition.urlForDataImportField;
            if (allowImport && !LDH.IsObjectNull(url) && !LDH.IsValueEmpty(url)) {
                this.uiObjectInstances["buttonImport"].instance.option("disabled", false);
                let loadPanel = this.uiObjectInstances["gridview"].instance.option("loadPanel");
                loadPanel.enabled = false;
            } else {
                if (LDH.IsObjectNull(url) || LDH.IsValueEmpty(url)) {
                    LRH.ShowToast("Pre-check failed. Please specify the URL for the data import.", "error", 5000);
                } else {
                    LRH.ShowToast("Pre-check failed. Please correct the errors before importing.", "error", 5000);
                }
            }
        }

        let $stats = $("#leopard_dataimport_stats_" + that.props.dataViewId);
        $(".total", $stats).text(data.length);

        setTimeout(function () {
            that.setState({gridViewData: data}, function () {
                if (that.instanceInitialized === false) {
                    that.instanceInitialized = true;
                    LRH.AddDisposablePopupInstances(that);
                }
            });
        }, 100);
    };

    cancelButtonOnClick = () => {
        let instance = window.Global_PopupTempObjectInstance;
        if (!LDH.IsObjectNull(instance)) instance.hide();
    };

    abortButtonOnClick = () => {
        this.processAborted = true;
        this.uiObjectInstances["buttonAbort"].instance.option("disabled", true);
    };

    exportButtonOnClick = () => {
        this.uiObjectInstances["gridview"].instance.exportToExcel(false);
    };

    importButtonOnClick = () => {
        let that = this;
        let $statusbar = $("#leopard_dataimport_statusbar_" + that.props.dataViewId);
        let $stats = $("#leopard_dataimport_stats_" + this.props.dataViewId);

        this.uiObjectInstances["buttonImport"].instance.option("disabled", true);
        $(".leopard-text-color.green", $stats).show();
        $(".leopard-text-color.orange", $stats).show();
        $(".leopard-text-color.red", $stats).show();

        setTimeout(function () {
            let data = window.Global_PopupTempData.data;
            let definition = window.Global_PopupTempData.definition;
            that.uiObjectInstances["progress"].instance.option("max", data.length);
            that.uiObjectInstances["progress"].instance.option("value", 0);
            that.uiObjectInstances["progress"].instance.option("visible", true);
            that.uiObjectInstances["buttonCancel"].instance.option("visible", false);
            that.uiObjectInstances["buttonImport"].instance.option("visible", false);
            that.uiObjectInstances["buttonAbort"].instance.option("visible", true);

            $(".leopard-import .leopard-spinner", $statusbar).show();
            window.Global_PopupTempObjectInstance.option("showCloseButton", false);

            let urlForImport = definition.urlForDataImportField;
            if (that.state.gridViewData.length > 0 && !LDH.IsObjectNull(urlForImport) &&
                !LDH.IsValueEmpty(urlForImport)) {
                $(".processed", $stats).text(0);
                $(".warning", $stats).text(0);
                $(".failed", $stats).text(0);

                that.importDataRecursiveProcess(urlForImport, 0, that.state.gridViewData);
            } else {
                LRH.ShowToast("Your import process has been successfully completed.", "success", 5000);
                that.finishUIComponents();
            }
        }, 500);
    };

    addUIObjectInstance = (data) => {
        this.uiObjectInstances[data.name] = data.instance;
    };

    onCellClick = (e) => {
        if (e.rowType === "data" && e.column.dataField === "Pre-check" &&
            !LDH.IsValueEmpty(e.data.ValidationErrors)) {
            alert(e.data.ValidationErrors, "Validation Errors");
        }
        if (e.rowType === "data" && e.column.dataField === "Processed" &&
            !LDH.IsValueEmpty(e.data.ServerErrors)) {
            alert(e.data.ServerErrors, "Validation Errors");
        }
    };

    onExporting = (e) => {
        e.component.columnOption("ServerErrors", "visible", true);
    };

    onExported = (e) => {
        e.component.columnOption("ServerErrors", "visible", false);
    };

    onResizeEnd = (e) => {
        this.setState({
            popupHeight: e.component.option("height"),
            popupWidth: e.component.option("width")
        });
    };

    onCellPrepared = (e) => {
        if (e.rowType === "data" && e.column.dataField === "ValidationErrors") {
            e.cellElement.className = "validation-errors";
        } else if (e.column.dataField === "Pre-check" || e.column.dataField === "Processed") {
            e.column.alignment = "center";
            e.column.width = "110px";
            e.column.encodeHtml = false;

            if (e.rowType === "data" && e.value.toLowerCase() === "pass") {
                e.cellElement.innerHTML =
                    "<div class='leopard-text-center leopard-text-color green'>" +
                    "     <b>" + e.value + "</b>" +
                    "</div>";
            } else if (e.rowType === "data" && e.value.toLowerCase() === "fail") {
                e.cellElement.innerHTML =
                    "<div class='leopard-text-center leopard-text-color red leopard-error-cell'>" +
                    "     <b>" + e.value + "</b>" +
                    "</div>";
            } else if (e.rowType === "data" && e.value.toLowerCase() === "warning") {
                e.cellElement.innerHTML =
                    "<div class='leopard-text-center leopard-text-color orange leopard-error-cell'>" +
                    "     <b>" + e.value + "</b>" +
                    "</div>";
            }
        }

        if (e.rowType === "header") {
            e.cellElement.innerHTML = "<div class='leopard-text-color orange'>" +
                e.cellElement.textContent + "</div>";
        }
    };

    customizeColumns = (e) => {
        for (let i = 0; i < e.length; i++) {
            let field = e[i].dataField;
            if (field === "Pre-check" || field === "Processed") {
                e[i].fixed = true;
                e[i].fixedPosition = "right";
            }
            if (field === "ValidationErrors" || field === "ServerErrors") {
                e[i].visible = false;
            }
            e[i].selectedFilterOperation = "contains";
        }
    };

    // =======================================================================
    // ============================= UI Logic ================================
    // =======================================================================
    importDataRecursiveProcess = (urlPath, index, dataSource) => {
        let that = this;
        if (that.processAborted === true) {
            LRH.ShowToast("Your import process has been aborted by the user.", "error", 5000);
            that.finishUIComponents();
            return;
        }
        if (dataSource.length <= index) {
            LRH.ShowToast("Your import process has been successfully completed.", "success", 5000);
            that.finishUIComponents();
            return;
        }
        let jsonData = LDH.DeepClone(dataSource[index]);
        delete jsonData["Processed"];
        delete jsonData["Pre-check"];
        delete jsonData["ValidationErrors"];
        delete jsonData["ServerErrors"];

        LeopardAjaxHelper.GridViewCRUD_InsertData(urlPath, jsonData, function (data) {
            let newIndex = index + 1;
            let $stats = $("#leopard_dataimport_stats_" + that.props.dataViewId);

            if (!LDH.IsObjectNull(data.message) && LDH.IsObjectNull(data.message.message) &&
                data.message.toString().toLowerCase().indexOf("user already exist") > -1) {
                dataSource[index].Processed = "FAIL";
                dataSource[index].ServerErrors = JSON.stringify(data.message);

                let count = parseInt($(".warning", $stats).text());
                $(".warning", $stats).text(count + 1);
            } else if (!LDH.IsObjectNull(data.message) && !LDH.IsObjectNull(data.message.message) &&
                data.message.message.indexOf("returned successfully") > -1) {
                dataSource[index].Processed = "PASS";

                let count = parseInt($(".processed", $stats).text());
                $(".processed", $stats).text(count + 1);
            } else {
                dataSource[index].Processed = "FAIL";
                dataSource[index].ServerErrors = JSON.stringify(data.message);

                let count = parseInt($(".failed", $stats).text());
                $(".failed", $stats).text(count + 1);
            }

            that.uiObjectInstances["gridview"].instance.repaintRows(index);
            that.uiObjectInstances["progress"].instance.option("value", newIndex);
            that.importDataRecursiveProcess(urlPath, newIndex, dataSource);
        }, function (error) {
            let $stats = $("#leopard_dataimport_stats_" + that.props.dataViewId);
            let newIndex = index + 1;

            dataSource[index].Processed = "FAIL";
            dataSource[index].ServerErrors = JSON.stringify(error.message);

            let count = parseInt($(".failed", $stats).text());
            $(".failed", $stats).text(count + 1);
            that.uiObjectInstances["gridview"].instance.repaintRows(index);
            that.uiObjectInstances["progress"].instance.option("value", newIndex);
            that.importDataRecursiveProcess(urlPath, newIndex, dataSource);
        });
    };

    // =======================================================================
    // ============================ UI Templates =============================
    // =======================================================================
    initializeDataImportGridView() {
        return (
            <DataGrid dataSource={this.state.gridViewData} columnMinWidth={80}
                      height={this.state.contentHeight} columnAutoWidth={true}
                      customizeColumns={(e) => this.customizeColumns(e)}
                      onCellPrepared={(e) => this.onCellPrepared(e)}
                      headerFilter={{allowSearch: true, visible: true}}
                      columnResizingMode={"widget"} repaintChangesOnly={true}
                      onExporting={(e) => this.onExporting(e)}
                      onExported={(e) => this.onExported(e)}
                      onCellClick={(e) => this.onCellClick(e)} cacheEnabled={true}
                      allowColumnResizing={true} allowColumnReordering={true}
                      loadPanel={{text: "Validating"}} scrolling={{mode: "virtual"}}
                      columnRenderingMode={"virtual"} rowRenderingMode={"virtual"}
                      ref={(e) => this.addUIObjectInstance({name: "gridview", instance: e})}
                      filterRow={{visible: true}} sorting={{mode: "multiple"}}>
                <Paging enabled={false} defaultPageSize={15}></Paging>
                <Pager visible={false} showPageSizeSelector={false}
                       allowedPageSizes={[15, 30, 50, 100]} infoText={"{0} of {1}"}
                       showInfo={true} showNavigationButtons={true}></Pager>
                <ColumnFixing enabled={true}></ColumnFixing>
            </DataGrid>
        );
    };

    initializeDataImportAccessPanel() {
        return (
            <div style={{textAlign: "right"}} id={"leopard_dataimport_statusbar_" + this.props.dataViewId}>
                <ProgressBar ref={(e) => this.addUIObjectInstance({name: "progress", instance: e})}
                             width="305px" min={0} value={0} visible={false}
                             style={{float: "left", paddingLeft: "10px", marginTop: "10px"}}>
                </ProgressBar>
                <span className={"leopard-import leopard-loading-icon"} style={{position: "absolute", right: "125px"}}>
                    <i className="leopard-spinner fas fa-spinner fa-pulse" style={{
                        color: "#FF8100", fontSize: "25px", display: "none"
                    }}></i>
                </span>
                <span style={{paddingRight: "10px"}}>
                    <Button className="leopard-button" text={'Cancel'} style={{marginRight: "15px"}}
                            ref={(e) => this.addUIObjectInstance({name: "buttonCancel", instance: e})}
                            onClick={(e) => this.cancelButtonOnClick(e)}>
                    </Button>
                    <Button className="leopard-button" text={'Export'} visible={false}
                            ref={(e) => this.addUIObjectInstance({name: "buttonExport", instance: e})}
                            onClick={(e) => this.exportButtonOnClick(e)}
                            style={{marginRight: "15px"}}>
                    </Button>
                    <Button className="leopard-button" text={'Import'}
                            ref={(e) => this.addUIObjectInstance({name: "buttonImport", instance: e})}
                            onClick={(e) => this.importButtonOnClick(e)}>
                    </Button>
                    <Button className="leopard-button" text={'Finish'} visible={false}
                            ref={(e) => this.addUIObjectInstance({name: "buttonFinish", instance: e})}
                            onClick={(e) => this.cancelButtonOnClick(e)}>
                    </Button>
                    <Button className="leopard-button" text={'Abort'} visible={false}
                            ref={(e) => this.addUIObjectInstance({name: "buttonAbort", instance: e})}
                            onClick={(e) => this.abortButtonOnClick(e)}>
                    </Button>
                </span>
            </div>
        );
    };

    initializeDataImportStatusPanel() {
        return (
            <div className={"leopard-popup-gridview-status"}
                 id={"leopard_dataimport_stats_" + this.props.dataViewId}>
                <span style={{paddingRight: "15px"}}>
                    <b>TOTAL: <span className={"total"}>0</span></b>
                </span>
                <span className={"leopard-text-color green"}
                      style={{paddingRight: "15px", display: "none"}}>
                    <b>PROCESSED: <span className={"processed"}>0</span></b>
                </span>
                <span className={"leopard-text-color orange"}
                      style={{paddingRight: "15px", display: "none"}}>
                    <b>WARNING: <span className={"warning"}>0</span></b>
                </span>
                <span className={"leopard-text-color red"}
                      style={{paddingRight: "15px", display: "none"}}>
                    <b>FAILED: <span className={"failed"}>0</span></b>
                </span>
            </div>
        );
    };

    // =======================================================================
    // ============================ UI Rendering =============================
    // =======================================================================
    render() {
        if (this.disposingAllInstances) return;

        return (
            <Popup className={'leopard-popup-window edit-form'} visible={this.state.popupVisible}
                   dragEnabled={true} closeOnOutsideClick={false} onHiding={this.popupOnHide}
                   onShowing={this.popupOnShowing} showTitle={true} title={this.state.popupTitle}
                   ref={this.props.popupDataImportInstance} onShown={this.popupOnShown}
                   fullScreen={false} width={this.state.popupWidth} height={this.state.popupHeight}
                   resizeEnabled={true} minHeight={"400px"} minWidth={"600px"}
                   position={this.state.popupPosition} onResizeEnd={(e) => this.onResizeEnd(e)}>
                <div className={"leopard-dataimport-popup-container"} style={{
                    marginBottom: "20px", overflow: "hidden", paddingLeft: "10px", paddingRight: "10px"
                }}>{this.initializeDataImportGridView()}</div>
                {this.initializeDataImportStatusPanel()}
                {this.initializeDataImportAccessPanel()}
            </Popup>
        );
    };
}

export default LeopardDataImportPopup;
